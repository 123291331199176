import mitt, { Emitter } from "mitt";

export default {
  install: (app: any) => {
    const emitter = mitt();
    app.provide("bus", emitter);
  },
};

declare module "vue" {
  interface ComponentCustomProperties {
    $bus: Emitter<any>;
  }
}
