<template>
  <div id="app">
    <metainfo>
      <template v-slot:title="{ content }"
        >{{ content ? `${content} | Gaz Européen` : `Gaz Européen` }}
      </template>
    </metainfo>
    <TopBar v-if="show_header" />
    <MainNavigation v-if="show_header" />
    <div id="content" :class="{ hide_header: !show_header }">
      <router-view :key="$route.fullPath"></router-view>
    </div>
    <BottomBar />
    <button
      class="floating_button button_new_request"
      v-if="show_header && ticket_access && isProduction"
      @click="display_modal"
    >
      <span class="icon icon-question"></span>
      <span>Nouvelle demande</span>
    </button>
    <FullPageModal
      v-if="show_header && show_modal"
      v-on:close="show_modal = !show_modal"
    >
      <template v-slot:body>
        <FormRequest
          v-on:close="show_modal = !show_modal"
          v-on:confirm="display_confirm($event)"
          :name="'Nouvelle demande'"
        />
      </template>
    </FullPageModal>

    <BaseModal class="modal-information" v-if="show_header && is_sent">
      <template v-slot:body>
        <div class="modal-content">
          <p class="modal-message">{{ message }}</p>
          <button
            class="modal-button button_new_request"
            @click="is_sent = false"
          >
            Fermer
          </button>
          <button class="router-link" @click="go_to_requests">
            Voir mes demandes
          </button>
        </div>
      </template>
    </BaseModal>
    <BaseLoader />
  </div>
</template>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script setup lang="ts">
import { computed, inject, onMounted, Ref, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";
import TopBar from "@/components/TopBar.vue";
import MainNavigation from "@/components/MainNavigation.vue";
import BottomBar from "@/components/BottomBar.vue";
import FullPageModal from "@/components/FullPageModal.vue";
import FormRequest from "@/components/FormRequest.vue";
import BaseModal from "@/components/BaseModal.vue";
import BaseLoader from "@/components/BaseLoader.vue";

const route = useRoute();
const router = useRouter();
const $bus = inject("bus");
const $api = inject("api");

const { ticket_access, ticket, last_visited_pages } = storeToRefs(useStore());
const { load_background_datas_from_api } = useStore();

const show_modal = ref(false);
const is_sent = ref(false);
const message = ref("");
const isProduction = ref(false);

const show_header = computed(() => {
  return (
    !route.meta.is_offline_page &&
    !(
      route.meta.offline_access
      // && !this.$root.is_online
    )
  );
});

const background_initial_datas = async function () {
  const status: string = await load_background_datas_from_api($api);
  if (status === "success") {
    $bus.emit("init_bar_chart");
    $bus.emit("init_bills_results");
    $bus.emit("init_consumptions_results");
  } else if (status === "unknown")
    console.log("background datas download error...");
};

const display_modal = function () {
  show_modal.value = !show_modal.value;
  ticket.value = null;
};

const display_confirm = function (value: boolean) {
  is_sent.value = true;
  if (value) {
    message.value = "Votre demande a bien été prise en compte.";
  } else {
    message.value =
      "Erreur lors de l'enregistrement ou l'envoi de votre demande";
  }
};
const go_to_requests = function () {
  is_sent.value = false;
  router.push("/requests");
};

const visited_pages: Ref<Array<any>> = ref([]);

watch(
  () => route,
  (from) => {
    if (!from.meta.is_offline_page) {
      visited_pages.value.push({
        name: from.name,
        title: from.meta.title,
        fullpath: from.fullPath,
      });
      if (visited_pages.value.length > 1) {
        last_visited_pages.value.push(visited_pages.value.slice(-2, -1).pop());
        if (last_visited_pages.value.length > 3) last_visited_pages.value.shift();
      }
    }
  }
);

onMounted(() => {
  isProduction.value = window.location.hostname === "clients.gaz-europeen.com";
  $bus.on("load_background_initial_datas", background_initial_datas);
});
</script>
