import type { FetchOptions } from "ofetch";
import { $fetch } from "ofetch";
import AccountModule from "@/repository/modules/account";
import ActualitiesModule from "@/repository/modules/actualities";
import GeocodeModule from "@/repository/modules/geaocode";
import GraphsModules from "@/repository/modules/graphs";
import RequestModule from "@/repository/modules/request";
import ExportModule from "@/repository/modules/export";

interface IApiInstance {
  account: AccountModule;
  actualities: ActualitiesModule;
  geocode: GeocodeModule;
  graphs: GraphsModules;
  request: RequestModule;
  export: ExportModule;
}

export default {
  install: (app: any) => {
    let api_admin_base_url = "https://admin-clients.gaz-europeen.com/api/";

    // UAT
    if (window.location.hostname === "uat-clients.gaz-europeen.com")
      api_admin_base_url = "https://uat-admin-clients.gaz-europeen.com/api/";

    // PRODUCTION
    if (window.location.hostname === "clients.gaz-europeen.com")
      api_admin_base_url = "https://admin-clients.gaz-europeen.com/api/";

    /** define custom options for $fetcher */
    const fetchOptions: FetchOptions = {
      baseURL: api_admin_base_url,
    };

    /** create a new instance of $fetcher with custom option */
    const apiFetcher = $fetch.create(fetchOptions);

    /** an object containing all repositories we need to expose */
    const modules: IApiInstance = {
      account: new AccountModule(apiFetcher),
      actualities: new ActualitiesModule(apiFetcher),
      geocode: new GeocodeModule(apiFetcher),
      graphs: new GraphsModules(apiFetcher),
      request: new RequestModule(apiFetcher),
      export: new ExportModule(apiFetcher),
    };

    app.provide("api", modules);
  },
};

declare module "vue" {
  interface ComponentCustomProperties {
    $api: IApiInstance;
  }
}
