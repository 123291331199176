import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, unref as _unref, Fragment as _Fragment, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "type" }
const _hoisted_2 = { class: "details" }
const _hoisted_3 = {
  key: 0,
  class: "hidden"
}
const _hoisted_4 = ["href", "data-tracking"]
const _hoisted_5 = ["href", "data-tracking"]

import { onMounted, ref } from "vue";
import spacePhone from "../utils/spacePhone";

const default_name = "Voir les coordonnées";

export default /*@__PURE__*/_defineComponent({
  __name: 'BaseContact',
  props: {
  account: {
    type: Object,
    default: null,
    required: false,
  },
  type: {
    type: String,
    default: "",
    required: false,
  },
  force_open: {
    type: Boolean,
    default: false,
    required: false,
  },
},
  setup(__props) {

const props = __props;

const show_detail = ref(false);
const title = ref("");
const name = ref("");
const email = ref("");
const phone = ref("");

onMounted(() => {
  if (props.type === "commercial" && props.account) {
    title.value = "Interlocuteur commercial";
    name.value = props.account?.commercial_contact_name;
    email.value = props.account?.commercial_contact_email;
    phone.value = props.account?.commercial_contact_phone;
  } else if (props.type === "billing" && props.account) {
    title.value = "Service facturation";
    name.value = default_name;
    email.value = props.account?.billing_contact_name;
    phone.value = props.account?.billing_contact_phone;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["interlocutor", __props.type])
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(title.value), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: _normalizeClass(["trigger", { default_name: name.value === default_name }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (show_detail.value = !show_detail.value))
      }, [
        _createTextVNode(_toDisplayString(name.value) + " ", 1),
        (!__props.force_open)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(["icon-angle_down", { invert: show_detail.value }])
            }, _cache[1] || (_cache[1] = [
              _createElementVNode("span", { class: "sr-only" }, "En savoir plus", -1)
            ]), 2))
          : _createCommentVNode("", true)
      ], 2),
      _createVNode(_Transition, { name: "slide_fade" }, {
        default: _withCtx(() => [
          (show_detail.value || __props.force_open)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                (email.value)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: 'mailto:' + email.value.toLowerCase(),
                      "data-tracking": 'header__mailto__mail_' + __props.type
                    }, "Contacter par email", 8, _hoisted_4))
                  : _createCommentVNode("", true),
                (phone.value)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                      _cache[3] || (_cache[3] = _createTextVNode(" Tél : ")),
                      _createElementVNode("a", {
                        href: 'tel:' + phone.value,
                        "data-tracking": 'header__tel__phone_' + __props.type
                      }, _toDisplayString(_unref(spacePhone)(phone.value)), 9, _hoisted_5)
                    ], 64))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ], 2))
}
}

})