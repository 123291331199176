export default function (json_filters: any) {
  let filter_string = "?filters=";
  let filters = "";
  const fixedKeys = [
    "Energie",
    "FactureStatut",
    "MoisDebut",
    "MoisFin",
    "AnneeDebut",
    "AnneeFin",
  ];

  Object.keys(json_filters).forEach((key, index) => {
    if (index > 0 && index < Object.keys(json_filters).length)
      filters += " and ";

    // Exception pour le statut "A régler" qui comprend en fait la reunion de 3 statut
    if (key === "FactureStatut" && json_filters[key] === "1")
      filters +=
        "(FactureStatut eq '1' or FactureStatut eq '3' or FactureStatut eq '4')";
    else if (fixedKeys.includes(key))
      filters += key + " eq '" + json_filters[key] + "'";
    else filters += key + " eq '*" + json_filters[key] + "*'";
  });

  if (filters !== "") {
    filter_string += encodeURIComponent(btoa(filters));
  }
  return filter_string;
}
