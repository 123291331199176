<script setup lang="ts">
import { defineProps, onMounted, ref } from "vue"

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
});

const title = ref("");
const phone = ref("");
const time = ref("");
const additionnal_informations = ref("");

onMounted(() => {
  if (props.type === "gas") {
    title.value = "Service dépannage Gaz";
    phone.value = "<a href='tel:0800433333'>0 800 43 33 33</a>";
    time.value = "24h/24 7J/7";
  } else if (props.type === "electricity") {
    title.value = "Service dépannage Électricité";
    phone.value = "0 972 675 0XX*";
    time.value = "24h/24 7J/7";
    additionnal_informations.value = "*numéro de votre département";
  }
});
</script>

<template>
  <div class="service" :class="type">
    <span class="type">
      {{ title }}
    </span>
    <div class="details">
      <span class="phone" v-html="phone"></span><br />
      <span class="time" v-html="time"></span>
      <span class="additionnal_informations" v-if="additionnal_informations">{{
        additionnal_informations
      }}</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.service {
    text-align: left;
  &.gas {
    .type {
      color: $dodger_blue;
    }
  }

  &.electricity {
    .type {
      color: $marigold;
    }
  }

  .type {
    font-size: 11px;
    letter-spacing: -0.3px;
    white-space: nowrap;

    @media #{$small_desktop} {
      display: block;
      margin-top: 15px;
      letter-spacing: 0;
      font-size: 14px;
    }
  }

  .details {
    position: relative;
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    margin-top: 3px;

    .additionnal_informations {
      position: absolute;
      bottom: -13px;
      left: 0;
      white-space: nowrap;
      font-size: 12px;
      font-weight: 400;
      color: $dark_grey;

      @media #{$small_desktop} {
        position: relative;
        bottom: 0;
        left: 0;
      }
    }
  }
}
</style>
