import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { createMetaManager } from "vue-meta";
import api from "@/plugins/api";
import bus from "@/plugins/bus";
import storeReset from "@/store/plugins/storeReset";
import { vTooltip } from "floating-vue";
import "floating-vue/dist/style.css";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(storeReset);
const app = createApp(App);
app.use(pinia);
app.use(api);
app.use(router);
app.use(bus);
app.use(createMetaManager());
app.directive("tooltip", vTooltip);
app.mount("#app");
