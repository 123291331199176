import type { $Fetch } from "ofetch";
import { storeToRefs } from "pinia";
import { useStore } from "@/store";

class HttpFactory {
  private readonly $fetch: $Fetch;
  private readonly token: any;

  constructor(fetcher: $Fetch) {
    this.$fetch = fetcher;
    const { token } = storeToRefs(useStore());
    this.token = token;
  }

  /**
   * @param method HTTP method
   * @param url URL
   * @param data Data to send
   * @param query Query parameters
   * @param extras Extra options
   * @param headers Headers
   * @returns Promise
   * @example
   * const $res = await this.call<ICreateAccountResponse>('POST', `${this.RESOURCE}/register`, account);
   */
  async call<T>(
    method: string,
    url: string,
    data?: object,
    query?: object,
    extras = {},
    headers = {},
    logged = false,
    no_ext: boolean = false
  ): Promise<T> {
    const headersCall: HeadersInit = { ...headers };
    query = {
      ...query
    };

    if (logged) {
      if (!no_ext)
        url = `ext_ws/${url}`;
      headersCall.Authorization = `Bearer ${this.token.value}`;
    }

    return await this.$fetch(url, {
      method,
      body: method === "POST" ? data : null,
      query,
      headers: headersCall,
      ...extras
    });
  }
}

export default HttpFactory;
