import HttpFactory from "@/repository/factory";

class GeocodeModule extends HttpFactory {
  private RESOURCE = "geocode";

  async getGeocode(address: string): Promise<any> {
    return await this.call<any>("POST", `${this.RESOURCE}`, { address });
  }
}

export default GeocodeModule;
