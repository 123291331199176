<script setup lang="ts">
import { defineProps, onMounted, ref } from "vue";
import spacePhone from "../utils/spacePhone";

const props = defineProps({
  account: {
    type: Object,
    default: null,
    required: false,
  },
  type: {
    type: String,
    default: "",
    required: false,
  },
  force_open: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const show_detail = ref(false);
const default_name = "Voir les coordonnées";
const title = ref("");
const name = ref("");
const email = ref("");
const phone = ref("");

onMounted(() => {
  if (props.type === "commercial" && props.account) {
    title.value = "Interlocuteur commercial";
    name.value = props.account?.commercial_contact_name;
    email.value = props.account?.commercial_contact_email;
    phone.value = props.account?.commercial_contact_phone;
  } else if (props.type === "billing" && props.account) {
    title.value = "Service facturation";
    name.value = default_name;
    email.value = props.account?.billing_contact_name;
    phone.value = props.account?.billing_contact_phone;
  }
});
</script>

<template>
  <div class="interlocutor" :class="type">
    <span class="type">
      {{ title }}
    </span>
    <div class="details">
      <span
        class="trigger"
        :class="{ default_name: name === default_name }"
        @click="show_detail = !show_detail"
      >
        {{ name }}
        <span
          class="icon-angle_down"
          :class="{ invert: show_detail }"
          v-if="!force_open"
        >
          <span class="sr-only">En savoir plus</span>
        </span>
      </span>
      <transition name="slide_fade">
        <span class="hidden" v-if="show_detail || force_open">
          <a
            :href="'mailto:' + email.toLowerCase()"
            v-if="email"
            :data-tracking="'header__mailto__mail_' + type"
            >Contacter par email</a
          >
          <template v-if="phone">
            <br />
            Tél :
            <a
              :href="'tel:' + phone"
              :data-tracking="'header__tel__phone_' + type"
              >{{ spacePhone(phone) }}</a
            >
          </template>
        </span>
      </transition>
    </div>
  </div>
</template>

<style scoped lang="scss">
.interlocutor {
  text-align: left;
  &.billing {
    .type {
      @media #{$small_desktop} {
        margin-top: 15px;
      }
    }
  }

  .type {
    font-size: 13px;
    font-weight: 500;

    @media #{$small_desktop} {
      display: block;
      color: $dodger_blue;
    }
  }

  .details {
    .trigger {
      display: flex;
      align-items: center;
      margin-top: 6px;
      cursor: pointer;
      @include font_input;

      @media #{$small_desktop} {
        font-size: 14px;
        font-weight: 500;
        cursor: default;
      }

      &:hover {
        span[class^="icon-"] {
          animation-duration: 0.2s;
          animation-name: select_arrow_down;
          animation-iteration-count: infinite;
          animation-direction: alternate;

          &.invert {
            animation-name: select_arrow_up;
          }
        }
      }

      &.default_name {
        @media #{$small_desktop} {
          display: none;
        }
      }

      span[class^="icon-"] {
        margin-left: 10px;
        display: inline-block;
        font-size: 6px;
        color: $dodger_blue;
        transition: 0.2s ease-in-out;

        &.invert {
          transform: rotate(180deg);
        }
      }
    }

    .hidden {
      display: block;
      margin-top: 6px;
      font-size: 14px;
      line-height: 16px;

      @media #{$small_desktop} {
        margin-top: 4px;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
