import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-mask" }
const _hoisted_2 = { class: "modal-wrapper" }
const _hoisted_3 = { class: "modal-container full-width" }
const _hoisted_4 = { class: "modal-body" }



export default /*@__PURE__*/_defineComponent({
  __name: 'FullPageModal',
  emits: ["close"],
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, { name: "modal" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              class: "modal-default-button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
            }, _cache[1] || (_cache[1] = [
              _createElementVNode("span", { class: "icon-cross" }, null, -1)
            ])),
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "body")
            ])
          ])
        ])
      ])
    ]),
    _: 3
  }))
}
}

})