import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"
import _imports_0 from '../assets/img/logo.svg'


const _hoisted_1 = { id: "top_bar" }
const _hoisted_2 = { id: "top_bar_left" }
const _hoisted_3 = { id: "top_bar_center" }
const _hoisted_4 = { class: "contact_container" }
const _hoisted_5 = { class: "contact_trigger_container" }
const _hoisted_6 = {
  key: 0,
  class: "contact_detail"
}
const _hoisted_7 = { class: "details" }
const _hoisted_8 = { class: "title" }

import { ref } from "vue";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import BaseContact from "@/components/BaseContact.vue";
import BaseService from "@/components/BaseService.vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'TopBar',
  setup(__props) {

const router = useRouter();
const { account, $reset } = useStore();

const show_contacts_detail = ref(false);

const logout = function () {
  $reset();
  localStorage.clear();
  router.push("/login");
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        id: "logo",
        to: "/home"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "Gaz Européen - Gaz & Électricité - Groupe Butagaz"
          }, null, -1)
        ])),
        _: 1
      })
    ]),
    _cache[7] || (_cache[7] = _createElementVNode("span", { class: "separator" }, null, -1)),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, [
          _createElementVNode("span", {
            class: "contact_trigger",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (show_contacts_detail.value = !show_contacts_detail.value))
          }, [
            _cache[3] || (_cache[3] = _createTextVNode(" Contacts ")),
            _createElementVNode("span", {
              class: _normalizeClass(["icon-angle_down", { invert: show_contacts_detail.value }])
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("span", { class: "sr-only" }, "En savoir plus", -1)
            ]), 2)
          ]),
          _createVNode(_Transition, { name: "slide_fade" }, {
            default: _withCtx(() => [
              (show_contacts_detail.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(BaseContact, {
                      account: _unref(account),
                      type: "commercial",
                      force_open: true
                    }, null, 8, ["account"]),
                    _createVNode(BaseContact, {
                      account: _unref(account),
                      type: "billing",
                      force_open: true
                    }, null, 8, ["account"]),
                    _createVNode(BaseService, { type: "gas" }),
                    _createVNode(BaseService, { type: "electricity" })
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ])
      ]),
      _createVNode(BaseContact, {
        account: _unref(account),
        type: "commercial",
        class: "block_info"
      }, null, 8, ["account"]),
      _createVNode(BaseContact, {
        account: _unref(account),
        type: "billing",
        class: "block_info"
      }, null, 8, ["account"]),
      _createVNode(BaseService, {
        type: "gas",
        class: "block_info"
      }),
      _createVNode(BaseService, {
        type: "electricity",
        class: "block_info"
      }),
      _createVNode(_component_router_link, {
        to: "/my-account",
        class: "block_info my_account",
        "data-tracking": "header__link__my_account"
      }, {
        default: _withCtx(() => [
          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "icon-lock" }, null, -1)),
          _createElementVNode("span", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, [
              (_unref(account)?.name_group)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_unref(account).name_group), 1)
                  ], 64))
                : (_unref(account)?.name)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(_unref(account).name), 1)
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createTextVNode(" Mon compte ")
                    ], 64))
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "title_mobile" }, " Mon compte ", -1))
          ])
        ]),
        _: 1
      })
    ]),
    _cache[8] || (_cache[8] = _createElementVNode("span", { class: "separator" }, null, -1)),
    _createElementVNode("div", { id: "top_bar_right" }, [
      _createElementVNode("button", {
        id: "logout",
        onClick: logout,
        "data-tracking": "header__link__logout"
      }, _cache[6] || (_cache[6] = [
        _createElementVNode("span", { class: "text" }, "Quitter", -1),
        _createElementVNode("span", { class: "icon-exit" }, null, -1)
      ]))
    ])
  ]))
}
}

})