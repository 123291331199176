export default function (site_id: string | null = null) {
  const filters: any = {
    MoisDebut: ("0" + (new Date().getMonth() + 1)).slice(-2),
    AnneeDebut: new Date().getFullYear() - 1,
    MoisFin: ("0" + (new Date().getMonth() + 1)).slice(-2),
    AnneeFin: new Date().getFullYear(),
  };

  if (site_id) filters["SiteId"] = site_id;
  else filters["FactureStatut"] = "1";

  return filters;
}
