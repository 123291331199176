import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, unref as _unref, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "form_container" }
const _hoisted_2 = { class: "orange_title" }
const _hoisted_3 = { class: "formline" }
const _hoisted_4 = {
  key: 0,
  class: "group_title"
}
const _hoisted_5 = { class: "group_title" }
const _hoisted_6 = { class: "groupfield otherfields" }
const _hoisted_7 = {
  key: 0,
  class: "otherfields"
}
const _hoisted_8 = {
  key: 1,
  type: "submit",
  class: "button button_submit"
}
const _hoisted_9 = {
  key: 2,
  type: "submit",
  class: "button button_submit"
}

import { computed, Ref, ref } from "vue";
import { storeToRefs } from "pinia";
import { useStore } from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'FormRequest',
  props: {
  name: {
    type: String,
    required: false,
  },
},
  emits: ["close", "confirm"],
  setup(__props, { emit: __emit }) {



const emit = __emit;

const {
  request_data,
  ticket,
  site_pce_list,
  site_prm_list,
  account,
  user_id,
  fields,
} = storeToRefs(useStore());

const request_type_index: Ref<number> = ref(-1);
const request_id = ref(null);
const energy_id = ref(null);
const sell_points = ref(null);

const request_form = computed(() => {
  if (request_type_index.value >= 0) {
    return request_data.value[request_type_index.value];
  } else if (ticket.value?.request) {
    return request_data.value[ticket.value.request.id - 1];
  } else return { error: "no request selected" };
});

const request_name = computed(() => {
  if (ticket.value?.request) return ticket.value.request.name;
  return null;
});

const request_data_filtered = computed(() => {
  let res: any[] = [];
  request_data.value.forEach((form) => {
    res.push(form);
  });

  return res;
});

const energy = computed(() => {
  if (ticket.value.energy) return ticket.value.energy.id;
  return energy_id.value;
});

const sell_point_options = computed(() => {
  if (energy.value === 2) return site_pce_list.value;
  else if (energy.value === 1) return site_prm_list.value;
  else return [];
});

const refresh_form = function (request_name: string) {
  request_type_index.value = request_data.value.findIndex(
    (request) => request.name === request_name
  );
  request_id.value = request_form.value.id;
  if (request_type_index.value !== -1) {
    let payload = {};
    // payload.request_type = this.request_form.id;
    // payload.energy_id = this.energy_id;
    request_form.value.groups.forEach((group: any) => {
      group.fields.forEach((field: any) => {
        if (field.name) {
          Object.assign(payload, { [field.name]: null });
        }
      });
    });
    if (request_form.value.fields) {
      request_form.value.fields.forEach((field: any) => {
        if (field.name) {
          Object.assign(payload, { [field.name]: null });
        }
      });
    }
    ticket.value = payload;
  }
};
const submit_form = function () {
  // this.$bus.$emit('loader', 'show');
  let formData = new FormData();
  if (user_id.value) formData.append("user_id", user_id.value);
  let payload = {};
  if (ticket.value.id) {
    formData.append("request_id", ticket.value.request.id);
    formData.append("energy_id", ticket.value.energy.id);
    if (sell_points.value)
      formData.append("delivery_points", sell_points.value);
    formData.append("parent_id", ticket.value.id);
    Object.keys(ticket.value.request_datas).forEach((key) => {
      if (key.indexOf("file") !== -1) {
        formData.append(key, ticket.value.request_datas[key]);
      } else Object.assign(payload, { [key]: ticket.value.request_datas[key] });
    });
  } else {
    if (request_id.value) formData.append("request_id", request_id.value);
    if (energy_id.value) formData.append("energy_id", energy_id.value);
    if (sell_points.value)
      formData.append("delivery_points", sell_points.value);
    if (account.value) {
      let commercial = account.value.commercial_contact_name.split(" ");
      formData.append("commercial_firstname", commercial[0]);
      formData.append("commercial_lastname", commercial[1]);
      formData.append(
        "commercial_email",
        account.value.commercial_contact_email
      );
    }

    let input = null;
    Object.keys(ticket.value).forEach((key) => {
      input = fields.value.find((x) => x.name === key);
      if (input.type === "file" && ticket.value[key]) {
        console.log(key.indexOf("file"));
        formData.append(key, ticket.value[key], ticket.value[key].name);
      } else
        Object.assign(payload, {
          [key]: ticket.value[key],
        });
    });
  }
  formData.append("request_datas", JSON.stringify(payload));
  // post_new_request(formData)
  //   .then((response) => {
  //     if (response.data.status === "success") {
  //       let datas = null;
  //       if (Array.isArray(response.data.datas)) {
  //         datas = response.data.datas[0];
  //       } else datas = response.data.datas;
  //       if (!ticket.value.id) ticket.value = datas.ticket;
  //       setTimeout(() => {
  //         //   this.$bus.$emit("loader", "hide");
  //         emit("close");
  //       }, 500);
  //
  //       emit("confirm", "update");
  //       // return datas;
  //     } else emit("confirm", "error");
  //   })
  //   .catch((error: any) => {
  //     console.error(error);
  //     // this.$bus.$emit("loader", "hide");
  //     emit("confirm", "error");
  //   });
};

return (_ctx: any,_cache: any) => {
  const _component_form_select_component = _resolveComponent("form-select-component")!
  const _component_form_switch_component = _resolveComponent("form-switch-component")!
  const _component_form_group_address_component = _resolveComponent("form-group-address-component")!
  const _component_form_group_interlocutor_component = _resolveComponent("form-group-interlocutor-component")!
  const _component_form_group_wrapper_component = _resolveComponent("form-group-wrapper-component")!
  const _component_form_radio_component = _resolveComponent("form-radio-component")!
  const _component_form_checkbox_component = _resolveComponent("form-checkbox-component")!
  const _component_form_file_component = _resolveComponent("form-file-component")!
  const _component_form_textarea_component = _resolveComponent("form-textarea-component")!
  const _component_form_input_component = _resolveComponent("form-input-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(__props.name), 1),
    _createElementVNode("form", {
      id: "new_request_form",
      ref: request_form.value.name,
      onSubmit: _withModifiers(submit_form, ["prevent"])
    }, [
      _createVNode(_component_form_select_component, {
        select_data: {
          name: 'request_type',
          label: 'Type de demande',
          required: true,
          editable: true,
          options: request_data_filtered.value,
          value: request_name.value,
          no_label: true,
        },
        onSwitchRequestForm: _cache[0] || (_cache[0] = ($event: any) => (refresh_form($event)))
      }, null, 8, ["select_data"]),
      (!request_form.value.error)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_form_switch_component, {
                switch_data: { name: 'energy_type', value: energy.value },
                onChooseEnergy: _cache[1] || (_cache[1] = ($event: any) => (energy_id.value = $event))
              }, null, 8, ["switch_data"]),
              _createVNode(_component_form_select_component, {
                select_data: {
              name: 'sell_points',
              label: 'Point de livraison',
              required: true,
              editable: true,
              options: sell_point_options.value,
              no_label: true,
              multiple: true,
            },
                onUpdateSellPoints: _cache[2] || (_cache[2] = ($event: any) => (sell_points.value = $event))
              }, null, 8, ["select_data"])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(request_form.value.groups, (group, index) => {
              return (_openBlock(), _createElementBlock("section", { key: index }, [
                (group.name === 'customer_delivery_fields')
                  ? (_openBlock(), _createBlock(_component_form_group_address_component, {
                      key: 0,
                      name: group.name,
                      group_title: group.label,
                      fields: group.fields,
                      energy: energy.value
                    }, null, 8, ["name", "group_title", "fields", "energy"]))
                  : (group.name === 'technical_contact_fields')
                    ? (_openBlock(), _createBlock(_component_form_group_interlocutor_component, {
                        key: 1,
                        name: group.name,
                        group_title: group.label,
                        fields: group.fields,
                        energy: energy.value
                      }, null, 8, ["name", "group_title", "fields", "energy"]))
                    : (group.name === 'gaz_precisions_fields')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                          (energy.value === 2)
                            ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(group.label), 1))
                            : _createCommentVNode("", true),
                          _createVNode(_component_form_group_wrapper_component, {
                            name: group.name,
                            fields: group.fields,
                            energy: energy.value
                          }, null, 8, ["name", "fields", "energy"])
                        ], 64))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                          _createElementVNode("h2", _hoisted_5, _toDisplayString(group.label), 1),
                          _createElementVNode("div", _hoisted_6, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.fields, (field, index) => {
                              return (_openBlock(), _createElementBlock(_Fragment, null, [
                                (
                    field.type === 'select' &&
                    (field.energy_id === energy.value || !field.energy_id)
                  )
                                  ? (_openBlock(), _createBlock(_component_form_select_component, {
                                      class: _normalizeClass([field.name, { long_field: field.label.length > 20 }]),
                                      select_data: field,
                                      key: `field_${field.name}_${index}`
                                    }, null, 8, ["class", "select_data"]))
                                  : (
                    field.type === 'radio' &&
                    (field.energy_id === energy.value || !field.energy_id)
                  )
                                    ? (_openBlock(), _createBlock(_component_form_radio_component, {
                                        class: _normalizeClass([field.name, { long_field: field.label.length > 20 }]),
                                        radio_data: field,
                                        key: `field_${field.name}_${index}`
                                      }, null, 8, ["class", "radio_data"]))
                                    : (
                    field.type === 'checkbox' &&
                    (field.energy_id === energy.value || !field.energy_id)
                  )
                                      ? (_openBlock(), _createBlock(_component_form_checkbox_component, {
                                          class: _normalizeClass([field.name, { long_field: field.label.length > 40 }]),
                                          checkbox_data: field,
                                          key: `field_${field.name}_${index}`
                                        }, null, 8, ["class", "checkbox_data"]))
                                      : (
                    field.type === 'file' &&
                    (field.energy_id === energy.value || !field.energy_id)
                  )
                                        ? (_openBlock(), _createBlock(_component_form_file_component, {
                                            class: _normalizeClass([field.name, { long_field: field.label.length > 40 }]),
                                            input_data: field,
                                            key: `field_${field.name}_${index}`
                                          }, null, 8, ["class", "input_data"]))
                                        : (
                    field.type === 'textarea' &&
                    (field.energy_id === energy.value || !field.energy_id)
                  )
                                          ? (_openBlock(), _createBlock(_component_form_textarea_component, {
                                              class: _normalizeClass([field.name, { long_field: field.label.length > 40 }]),
                                              text_data: field,
                                              key: `field_${field.name}_${index}`
                                            }, null, 8, ["class", "text_data"]))
                                          : (field.energy_id === energy.value || !field.energy_id)
                                            ? (_openBlock(), _createBlock(_component_form_input_component, {
                                                class: _normalizeClass([field.name, { long_field: field.label.length > 40 }]),
                                                input_data: field,
                                                key: `field_${field.name}_${index}`
                                              }, null, 8, ["class", "input_data"]))
                                            : _createCommentVNode("", true)
                              ], 64))
                            }), 256))
                          ])
                        ], 64))
              ]))
            }), 128)),
            (request_form.value.fields)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(request_form.value.fields, (field, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                      (
                field.type === 'select' &&
                (field.energy_id === energy.value || !field.energy_id)
              )
                        ? (_openBlock(), _createBlock(_component_form_select_component, {
                            class: _normalizeClass([field.name, { long_field: field.label.length > 20 }]),
                            select_data: field,
                            key: `field_${field.name}_${index}`
                          }, null, 8, ["class", "select_data"]))
                        : (
                field.type === 'radio' &&
                (field.energy_id === energy.value || !field.energy_id)
              )
                          ? (_openBlock(), _createBlock(_component_form_radio_component, {
                              class: _normalizeClass([field.name, { long_field: field.label.length > 40 }]),
                              radio_data: field,
                              key: `field_${field.name}_${index}`
                            }, null, 8, ["class", "radio_data"]))
                          : (
                field.type === 'checkbox' &&
                (field.energy_id === energy.value || !field.energy_id)
              )
                            ? (_openBlock(), _createBlock(_component_form_checkbox_component, {
                                class: _normalizeClass([field.name, { long_field: field.label.length > 40 }]),
                                checkbox_data: field,
                                key: `field_${field.name}_${index}`
                              }, null, 8, ["class", "checkbox_data"]))
                            : (
                field.type === 'file' &&
                (field.energy_id === energy.value || !field.energy_id)
              )
                              ? (_openBlock(), _createBlock(_component_form_file_component, {
                                  class: _normalizeClass([field.name, { long_field: field.label.length > 40 }]),
                                  input_data: field,
                                  key: `field_${field.name}_${index}`
                                }, null, 8, ["class", "input_data"]))
                              : (
                field.type === 'textarea' &&
                (field.energy_id === energy.value || !field.energy_id)
              )
                                ? (_openBlock(), _createBlock(_component_form_textarea_component, {
                                    class: _normalizeClass([field.name, { long_field: field.label.length > 40 }]),
                                    text_data: field,
                                    key: `field_${field.name}_${index}`
                                  }, null, 8, ["class", "text_data"]))
                                : (field.energy_id === energy.value || !field.energy_id)
                                  ? (_openBlock(), _createBlock(_component_form_input_component, {
                                      class: _normalizeClass([field.name, { long_field: field.label.length > 40 }]),
                                      input_data: field,
                                      key: `field_${field.name}_${index}`
                                    }, null, 8, ["class", "input_data"]))
                                  : _createCommentVNode("", true)
                    ], 64))
                  }), 256))
                ]))
              : _createCommentVNode("", true),
            (_unref(ticket).id)
              ? (_openBlock(), _createElementBlock("button", _hoisted_8, " Enregistrer mes modifications "))
              : (_openBlock(), _createElementBlock("button", _hoisted_9, " Envoyer ma demande "))
          ], 64))
        : _createCommentVNode("", true)
    ], 544)
  ]))
}
}

})