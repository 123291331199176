<script setup lang="ts">
import { ref } from "vue";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import BaseContact from "@/components/BaseContact.vue";
import BaseService from "@/components/BaseService.vue"

const router = useRouter();
const { account, $reset } = useStore();

const show_contacts_detail = ref(false);

const logout = function () {
  $reset();
  localStorage.clear();
  router.push("/login");
};
</script>

<template>
  <header id="top_bar">
    <div id="top_bar_left">
      <router-link id="logo" to="/home">
        <img
          src="../assets/img/logo.svg"
          alt="Gaz Européen - Gaz & Électricité - Groupe Butagaz"
        />
      </router-link>
    </div>
    <span class="separator"></span>
    <div id="top_bar_center">
      <div class="contact_container">
        <span class="contact_trigger_container">
          <span
            class="contact_trigger"
            @click="show_contacts_detail = !show_contacts_detail"
          >
            Contacts
            <span
              class="icon-angle_down"
              :class="{ invert: show_contacts_detail }"
            >
              <span class="sr-only">En savoir plus</span>
            </span>
          </span>
          <transition name="slide_fade">
            <div class="contact_detail" v-if="show_contacts_detail">
              <BaseContact
                :account="account"
                type="commercial"
                :force_open="true"
              />
              <BaseContact
                :account="account"
                type="billing"
                :force_open="true"
              />
              <BaseService type="gas" />
              <BaseService type="electricity" />
            </div>
          </transition>
        </span>
      </div>
      <BaseContact :account="account" type="commercial" class="block_info" />
      <BaseContact :account="account" type="billing" class="block_info" />
      <BaseService type="gas" class="block_info" />
      <BaseService type="electricity" class="block_info" />
      <router-link
        to="/my-account"
        class="block_info my_account"
        data-tracking="header__link__my_account"
      >
        <span class="icon-lock"></span>
        <span class="details">
          <span class="title">
            <template v-if="account?.name_group">
              {{ account.name_group }}
            </template>
            <template v-else-if="account?.name">
              {{ account.name }}
            </template>
            <template v-else> Mon compte </template>
          </span>
          <span class="title_mobile"> Mon compte </span>
        </span>
      </router-link>
    </div>
    <span class="separator"></span>
    <div id="top_bar_right">
      <button id="logout" @click="logout" data-tracking="header__link__logout">
        <span class="text">Quitter</span>
        <span class="icon-exit"></span>
      </button>
    </div>
  </header>
</template>

<style scoped lang="scss">
#top_bar {
  display: flex;
  align-items: center;
  height: 102px;
  background-color: $white;

  &_left {
    padding-left: 15px;
    padding-right: 37px;

    @media #{$phone} {
      padding-right: 10px;
    }

    #logo {
      img {
        width: 129px;

        @media #{$phone} {
          width: 100px;
        }
      }
    }
  }

  &_center {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;

    .contact_container {
      display: none;
      flex: 1 2 auto;
      padding-left: 50px;

      @media #{$small_desktop} {
        display: block;
      }

      @media #{$phone} {
        flex: 1 1 auto;
        padding-left: 20px;
      }

      .contact_trigger_container {
        position: relative;

        .contact_trigger {
          display: flex;
          align-items: center;
          cursor: pointer;
          @include font_input;

          @media #{$phone} {
            font-size: 14px;
          }

          &:hover {
            span[class^="icon-"] {
              animation-duration: 0.2s;
              animation-name: select_arrow_down;
              animation-iteration-count: infinite;
              animation-direction: alternate;

              &.invert {
                animation-name: select_arrow_up;
              }
            }
          }

          span[class^="icon-"] {
            margin-left: 10px;
            display: inline-block;
            font-size: 8px;
            color: $dodger_blue;
            transition: 0.2s ease-in-out;

            &.invert {
              transform: rotate(180deg);
            }
          }
        }

        .contact_detail {
          position: absolute;
          top: 20px;
          left: 0;
          z-index: 10;
          background-color: $white;
          border-radius: 6px;
          padding: 10px;
          cursor: default;
          box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
        }
      }
    }

    .block_info {
        text-align: left;
      &.interlocutor,
      &.service {
        @media #{$small_desktop} {
          display: none;
        }
      }

      &.my_account {
        display: flex;
        align-items: center;
        padding: 0 10px;
        max-width: 250px;

        @media #{$small_desktop} {
          flex: 1 1 auto;
          max-width: initial;
        }

        &:hover {
          span[class^="icon-"] {
            color: darken($dodger_blue, 30%);
          }

          .details {
            .title {
              color: darken($dodger_blue, 30%);
            }

            .title_mobile {
              color: darken($dodger_blue, 30%);
            }
          }
        }

        span[class^="icon-"] {
          display: inline-block;
          font-size: 33px;
          color: $dodger_blue;
          margin-right: 13px;
          transition: 0.2s ease-in-out;

          @media #{$phone} {
            font-size: 18px;
            margin-right: 6px;
          }
        }

        .details {
          display: block;

          .title {
            display: block;
            font-size: 20px;
            font-weight: 500;
            color: $dodger_blue;
            transition: 0.2s ease-in-out;

            @media #{$phone} {
              display: none;
            }
          }

          .title_mobile {
            display: none;
            font-size: 12px;
            font-weight: 500;
            color: $dodger_blue;
            transition: 0.2s ease-in-out;

            @media #{$phone} {
              display: block;
              text-align: center;
            }
          }
        }
      }
    }
  }

  &_right {
    padding-left: 30px;
    padding-right: 32px;

    @media #{$phone} {
      padding-left: 0;
      padding-right: 10px;
    }

    #logout {
      display: flex;
      opacity: 0.5;
      font-size: 11px;
      line-height: 16px;
      font-weight: 500;
      padding: 5px; // Ajout d'un padding pour faciliter le clic sur le lien
      letter-spacing: 0.9px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      .text {
        @media #{$phone} {
          display: none;
        }
      }

      span[class^="icon-"] {
        display: inline-block;
        font-size: 16px;
        margin-left: 8px;
        transform: rotate(180deg);
      }
    }
  }

  .separator {
    display: inline-block;
    width: 1px;
    height: 70px;
    opacity: 0.7;
    background-color: #c5c5c5;
  }
}
</style>
