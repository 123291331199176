import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, TransitionGroup as _TransitionGroup, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "long-request"
}
const _hoisted_2 = {
  key: 0,
  class: "download_count"
}
const _hoisted_3 = {
  key: 1,
  class: "loader_slideshow"
}
const _hoisted_4 = ["src"]

import { inject, onMounted, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseLoader',
  setup(__props) {

const loader_state = ref("hide");
const loader_slideshow = ref(false);
const is_long_request = ref(false);
const slideshow_index = ref(1);
const slideshow_max_items = ref(5);
const loader_download_target = ref(0);
const loader_download_count = ref(0);
let slideshow_interval: any = null;

const reset_loader_download = function () {
  loader_download_target.value = 0;
  loader_download_count.value = 0;
};

const trigger_slideshow = function (init = false) {
  if (slideshow_index.value === slideshow_max_items.value)
    slideshow_index.value = 1;
  else if (!init) slideshow_index.value++;
};

const launch_interval = function () {
  trigger_slideshow(true);
  slideshow_interval = setInterval(() => {
    trigger_slideshow();
  }, 5000);
};

const $bus = inject("bus");

onMounted(() => {
  $bus.on("loader", (state: string) => {
    loader_state.value = state;
    reset_loader_download();
  });

  $bus.on("loader_slideshow", (state: boolean) => {
    slideshow_index.value = 1;
    if (state) launch_interval();
    else clearInterval(slideshow_interval);
    loader_slideshow.value = state;
  });

  $bus.on("loader_download_target", (value: number) => {
    loader_download_target.value = value;
  });

  $bus.on("loader_is_long_request", (value: boolean) => {
    is_long_request.value = value;
  });

  $bus.on("loader_download_count", (value: number) => {
    loader_download_count.value = value;
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: "loader",
    class: _normalizeClass(loader_state.value)
  }, [
    _createElementVNode("div", {
      id: "loader_wrapper",
      class: _normalizeClass({ with_slideshow: loader_slideshow.value })
    }, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "spinner" }, [
        _createElementVNode("div", { class: "bounce1" }),
        _createElementVNode("div", { class: "bounce2" }),
        _createElementVNode("div", { class: "bounce3" })
      ], -1)),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (is_long_request.value)
            ? (_openBlock(), _createElementBlock("p", _hoisted_1, _cache[0] || (_cache[0] = [
                _createTextVNode(" Nous récupérons des données très volumineuses, pour optimiser"),
                _createElementVNode("br", null, null, -1),
                _createTextVNode(" le temps de chargement, pensez à réduire la plage de recherche ")
              ])))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (loader_download_target.value > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _cache[1] || (_cache[1] = _createTextVNode(" Téléchargement en cours")),
            _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("span", null, _toDisplayString(loader_download_count.value) + " / " + _toDisplayString(loader_download_target.value), 1)
          ]))
        : _createCommentVNode("", true),
      (loader_slideshow.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_TransitionGroup, { name: "slide_left" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slideshow_max_items.value, (n) => {
                  return _withDirectives((_openBlock(), _createElementBlock("span", {
                    class: "slide",
                    key: n
                  }, [
                    _createElementVNode("img", {
                      src: '/loading_slideshow/' + n + '.png',
                      alt: ""
                    }, null, 8, _hoisted_4)
                  ])), [
                    [_vShow, slideshow_index.value === n]
                  ])
                }), 128))
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}
}

})