export default function (
  site_id: any = null,
  has_pce = true,
  site_count: any = null
) {
  const currentDate = new Date();
  let monthStart = ("0" + (currentDate.getMonth() + 1)).slice(-2);
  let yearStart = currentDate.getFullYear() - 1;
  let monthEnd = monthStart;
  let yearEnd = currentDate.getFullYear();

  if (site_count > 100) {
    monthStart = ("0" + currentDate.getMonth()).slice(-2);
    yearStart = currentDate.getFullYear();
    if (monthStart === "00") {
      monthStart = "12";
      yearStart = currentDate.getFullYear() - 1;
    }
    monthEnd = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    yearEnd = currentDate.getFullYear();
  }

  const filters: any = {
    Energie: has_pce ? "G" : "E",
    MoisDebut: monthStart,
    AnneeDebut: yearStart,
    MoisFin: monthEnd,
    AnneeFin: yearEnd,
  };

  if (site_id) filters["SiteId"] = site_id;

  return filters;
}
