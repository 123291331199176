<script setup lang="ts">
import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useStore } from "@/store";

const { ticket_access, account, sites } = storeToRefs(useStore());

const menu_open = ref(false);
const isProduction = ref(false);

onMounted(() => {
  isProduction.value = window.location.hostname === "clients.gaz-europeen.com";
});
const hide_mobile_menu = function () {
  menu_open.value = false;
};
</script>

<template>
  <nav id="main_navigation">
    <div
      id="burger_menu_trigger"
      :class="{ open: menu_open }"
      @click="menu_open ? (menu_open = false) : (menu_open = true)"
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <em class="sr-only">{{
        menu_open ? "Fermer le menu principal" : "Ouvrir le menu principal"
      }}</em>
    </div>
    <transition name="fade">
      <div class="nav_link_container" :class="{ menu_is_open: menu_open }">
        <router-link
          class="nav_link"
          to="/home"
          @click="hide_mobile_menu"
          data-tracking="header__link__navigation_home"
        >
          <span> Accueil </span>
        </router-link>
        <router-link
          class="nav_link"
          to="/search"
          :class="{ force_active: $route.meta.nav_search_active }"
          v-if="sites?.length > 1"
          @click="hide_mobile_menu"
          data-tracking="header__link__navigation_search"
        >
          <span> Mes sites </span>
        </router-link>
        <router-link
          class="nav_link"
          :class="{ with_alert: account?.total_very_late > 0 }"
          to="/bills"
          @click="hide_mobile_menu"
          data-tracking="header__link__navigation_my_bills"
        >
          <span> Mes factures </span>
        </router-link>
        <router-link
          class="nav_link"
          to="/consumptions"
          @click="hide_mobile_menu"
          data-tracking="header__link__navigation_my_consumptions"
        >
          <span> Mes consommations </span>
        </router-link>
        <!--<router-link class="nav_link" to="/documents" @click.native="hide_mobile_menu">
                    <span>
                        Mes documents
                    </span>
                </router-link>-->
        <router-link
          v-if="ticket_access && isProduction"
          class="nav_link"
          to="/requests"
          @click="hide_mobile_menu"
          data-tracking="header__link__navigation_my_requests"
        >
          <span> Mes demandes </span>
        </router-link>
        <router-link
          class="nav_link"
          to="/actualities"
          :class="{ force_active: $route.meta.nav_actuality_active }"
          @click="hide_mobile_menu"
          data-tracking="header__link__navigation_news"
        >
          <span> Actualités </span>
        </router-link>
      </div>
    </transition>
  </nav>
</template>

<style scoped lang="scss">
#main_navigation {
  position: relative;
  display: flex;
  height: 64px;
  background-image: linear-gradient(93deg, #113b66, #06111c);
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.02), 0 2px 9px 0 rgba(0, 0, 0, 0.02);

  @media #{$phone} {
    display: block;
  }

  #burger_menu_trigger {
    display: none;
    align-items: center;
    width: 40px;
    height: 32px;
    position: relative;
    margin: 16px;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;

    @media #{$phone} {
      display: inline-flex;
    }

    &:after {
      display: inline-block;
      content: "MENU";
      font-family: $font_sailec;
      font-size: 24px;
      padding-left: 65px;
      color: $white;
    }

    &.open {
      span {
        &:nth-child(1) {
          top: 18px;
          width: 0%;
          left: 50%;
        }

        &:nth-child(2) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }

        &:nth-child(4) {
          top: 18px;
          width: 0%;
          left: 50%;
        }
      }
    }

    span {
      display: block;
      position: absolute;
      height: 4px;
      width: 100%;
      background: $white;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        top: 0px;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 14px;
      }

      &:nth-child(4) {
        top: 28px;
      }
    }
  }

  .nav_link_container {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;

    @media #{$phone} {
      position: absolute;
      top: 64px;
      left: 0;
      width: 100%;
      display: none;
      justify-content: left;
      z-index: 2;
      animation-name: fade_appear_translate;
      animation-duration: 0.5s;
    }

    &.menu_is_open {
      @media #{$phone} {
        display: block;
      }
    }

    .nav_link {
      display: flex;
      align-self: stretch;
      align-items: center;
      position: relative;
      @include font_main_navigation;

      @media #{$phone} {
        display: flex;
        align-items: center;
        height: 50px;
        padding-left: 18px;
        font-size: 18px;
        background-image: linear-gradient(93deg, #113b66, #06111c);
      }

      &:hover,
      &.router-link-active,
      &.force_active {
        &:after {
          visibility: visible;
          transform: scaleX(1);
          height: 10px;

          @media #{$phone} {
            display: none;
          }
        }

        span {
          &:after {
            @media #{$phone} {
              opacity: 1;
            }
          }
        }
      }

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 0;
        bottom: 0;
        left: 0;
        background-color: $bright_red;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        box-shadow: 0px 0px 17px 0 $bright_red;
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.4s ease-in-out;
      }

      &.with_alert {
        span {
          &:before {
            content: "!";
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 15px;
            height: 15px;
            top: -8px;
            right: -19px;
            font-size: 9px;
            text-indent: 1px;
            color: $white;
            background-image: linear-gradient(to bottom, #ff7878, #ff4f4f);
            border-radius: 15px;
          }
        }
      }

      span {
        position: relative;

        &:after {
          @media #{$phone} {
            content: "";
            position: absolute;
            display: inline-block;
            width: 100%;
            height: 5px;
            bottom: -7px;
            left: 0;
            background-color: $bright_red;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
            box-shadow: 0px 0px 17px 0 $bright_red;
            opacity: 0;
            transition: all 0.4s ease-in-out;
          }
        }
      }
    }
  }
}
</style>
