import HttpFactory from "@/repository/factory";

class AccountModule extends HttpFactory {
  async login(email: string, password: string): Promise<any> {
    return await this.call<any>("POST", "authenticate_user", {
      email,
      password,
    });
  }

  async lostPassword(data: any): Promise<any> {
    return await this.call<any>("POST", "lost_password", data);
  }

  async resetPassword(data: any): Promise<any> {
    return await this.call<any>("POST", "reset_user_password", data);
  }

  async getAccountInformation(email: string): Promise<any> {
    return await this.call<any>(
      "GET",
      `getDetailsCompte/${email}`,
      {},
      {},
      {},
      {},
      true
    );
  }

  async getBillings(email: string, paramsString: string): Promise<any> {
    return await this.call<any>(
      "GET",
      `getFactures/${email}${paramsString}`,
      {},
      {},
      {},
      {},
      true
    );
  }

  async getBilling(email: string, bill_number: string): Promise<any> {
    return await this.call<any>(
      "GET",
      `getFacture/ISU${bill_number}?email=${email}`,
      {},
      {},
      {},
      {},
      true
    );
  }

  async getSites(email: string): Promise<any> {
    return await this.call<any>(
      "GET",
      `getSites/${email}`,
      {},
      {},
      {},
      {},
      true
    );
  }

  async getHistory(email: string, paramsString: string): Promise<any> {
    return await this.call<any>(
      "GET",
      `getHisto/${email}${paramsString}`,
      {},
      {},
      {},
      {},
      true
    );
  }

  async getReadings(email: string, paramsString: string): Promise<any> {
    return await this.call<any>(
      "GET",
      `getReadings/${email}${paramsString}`,
      {},
      {},
      {},
      {},
      true
    );
  }
}

export default AccountModule;
