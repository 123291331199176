import HttpFactory from "@/repository/factory";

class ExportModule extends HttpFactory {
  private RESOURCE = "export";

  async exportInvoices(data: any): Promise<any> {
    return await this.call<any>("POST", `${this.RESOURCE}/invoices`, data, {}, {}, {}, true, true);
  }

  async exportConsumptions(data: any): Promise<any> {
    return await this.call<any>("POST", `${this.RESOURCE}/consumption`, data, {}, {}, {}, true, true);
  }
}

export default ExportModule;
