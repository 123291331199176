import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useStore } from "@/store";
import { storeToRefs } from "pinia";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/offline",
    component: () => import("../views/offline/_layout.vue"),
    children: [
      {
        path: "login",
        alias: "/login", // Evite d'afficher le /offline/ dans l'url
        name: "login",
        meta: {
          is_offline_page: true,
        },
        component: () => import("../views/offline/LoginPage.vue"),
      },
      {
        path: "lost_password",
        alias: "/lost_password", // Evite d'afficher le /offline/ dans l'url
        name: "lost_password",
        meta: {
          is_offline_page: true,
        },
        component: () => import("../views/offline/LostPassword.vue"),
      },
      {
        path: "define_password/:encrypted_email",
        alias: "/define_password/:encrypted_email", // Evite d'afficher le /offline/ dans l'url
        name: "define_password",
        meta: {
          is_offline_page: true,
        },
        component: () => import("../views/offline/ResetPassword.vue"),
      },
      {
        path: "reset_password/:encrypted_email",
        alias: "/reset_password/:encrypted_email", // Evite d'afficher le /offline/ dans l'url
        name: "reset_password",
        meta: {
          is_offline_page: true,
        },
        component: () => import("../views/offline/ResetPassword.vue"),
      },
    ],
  },
  {
    path: "/my-account",
    name: "my_account",
    meta: {
      title: "Mon compte",
    },
    component: () => import("../views/AccountPage.vue"),
  },
  {
    path: "/home",
    name: "home",
    meta: {
      title: "Tableau de bord",
    },
    component: () => import("../views/HomePage.vue"),
  },
  {
    path: "/search",
    name: "search",
    meta: {
      title: "Mes sites",
    },
    component: () => import("../views/SearchPage.vue"),
  },
  {
    path: "/bills",
    name: "bills",
    meta: {
      title: "Mes factures",
    },
    component: () => import("../views/BillsPage.vue"),
  },
  {
    path: "/consumptions",
    name: "consumptions",
    meta: {
      title: "Mes consommations",
    },
    component: () => import("../views/ConsumptionsPage.vue"),
  },
  {
    path: "/documents",
    name: "documents",
    meta: {
      title: "Mes documents",
    },
    component: () => import("../views/DocumentsPage.vue"),
  },
  {
    path: "/legal-notice",
    name: "legal_notice",
    meta: {
      title: "Mentions légales",
      offline_access: true,
    },
    component: () => import("../views/LegalNoticePage.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy_policy",
    meta: {
      title: "Politique de confidentialité",
      offline_access: true,
    },
    component: () => import("../views/PrivacyPolicyPage.vue"),
  },
  {
    path: "/actualities",
    name: "actualities",
    meta: {
      title: "Actualités",
      nav_actuality_active: true,
    },
    component: () => import("../views/ActualitiesPage.vue"),
  },
  {
    path: "/actuality/:id",
    name: "actuality",
    meta: {
      title: "Actualité",
      nav_actuality_active: true,
    },
    component: () => import("../views/ActualityPage.vue"),
  },
  {
    path: "/site/:id",
    component: () => import("../views/site/_layout.vue"),
    children: [
      {
        path: "dashboard",
        name: "site_dashboard",
        meta: {
          title: "Tableau de bord",
          nav_search_active: true,
        },
        component: () => import("../views/site/DashboardPage.vue"),
      },
      {
        path: "consumptions",
        name: "site_consumptions",
        meta: {
          title: "Consommations",
          nav_search_active: true,
        },
        component: () => import("../views/site/ConsumptionsPage.vue"),
      },
      {
        path: "bills",
        name: "site_bills",
        meta: {
          title: "Facturation",
          nav_search_active: true,
        },
        component: () => import("../views/site/BillsPage.vue"),
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/login",
  },
];

// if (window.location.hostname === "clients.gaz-europeen.com") {
routes.push({
  path: "/requests",
  name: "requests",
  meta: {
    title: "Mes demandes",
  },
  component: () => import("../views/RequestsPage.vue"),
});
// }

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const { token, email, login_timestamp } = storeToRefs(useStore());
  // Si on est sur une page qui ne necessite pas d'authentification (login, forgot password, etc.)
  if (to.meta.is_offline_page || to.meta.offline_access) {
    next();
  }
  // Si on est sur une page qui necessite une authentification
  else {
    // console.log(store.state)
    const max_allowed = 60 * 60 * 1000; // 1H (ms)

    // On verifie que l'on a bien l'email et le token dans le store et que le login date d'il y a moins de 1h
    if (
      token.value &&
      email.value &&
      Date.now() < (login_timestamp.value || 0) + max_allowed
    ) {
      // Si c'est ok on remet le timestamp à jour
      login_timestamp.value = Date.now();
      next();
    }
    // Si on redirige vers la page de login
    else {
      next("/login?access_denied=true");
    }
  }
});

export default router;
