export default function (
  street_number: string,
  street_name: string,
  zip_code: string,
  city: string
) {
  return (
    street_number +
    " " +
    street_name +
    " " +
    zip_code +
    " " +
    city
  ).trim();
}
