import HttpFactory from "@/repository/factory";

class GraphsModules extends HttpFactory {
  async getCamenbert(email: string, paramsString: string): Promise<any> {
    return await this.call<any>(
      "GET",
      `getCamembert/${email}${paramsString}`,
      {},
      {},
      {},
      {},
      true
    );
  }
}

export default GraphsModules;
