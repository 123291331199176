import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "type" }
const _hoisted_2 = { class: "details" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "additionnal_informations"
}

import { onMounted, ref } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseService',
  props: {
  type: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const title = ref("");
const phone = ref("");
const time = ref("");
const additionnal_informations = ref("");

onMounted(() => {
  if (props.type === "gas") {
    title.value = "Service dépannage Gaz";
    phone.value = "<a href='tel:0800433333'>0 800 43 33 33</a>";
    time.value = "24h/24 7J/7";
  } else if (props.type === "electricity") {
    title.value = "Service dépannage Électricité";
    phone.value = "0 972 675 0XX*";
    time.value = "24h/24 7J/7";
    additionnal_informations.value = "*numéro de votre département";
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["service", __props.type])
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(title.value), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: "phone",
        innerHTML: phone.value
      }, null, 8, _hoisted_3),
      _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
      _createElementVNode("span", {
        class: "time",
        innerHTML: time.value
      }, null, 8, _hoisted_4),
      (additionnal_informations.value)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(additionnal_informations.value), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})