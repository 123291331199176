import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-message" }

import { computed, inject, onMounted, Ref, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";
import TopBar from "@/components/TopBar.vue";
import MainNavigation from "@/components/MainNavigation.vue";
import BottomBar from "@/components/BottomBar.vue";
import FullPageModal from "@/components/FullPageModal.vue";
import FormRequest from "@/components/FormRequest.vue";
import BaseModal from "@/components/BaseModal.vue";
import BaseLoader from "@/components/BaseLoader.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const route = useRoute();
const router = useRouter();
const $bus = inject("bus");
const $api = inject("api");

const { ticket_access, ticket, last_visited_pages } = storeToRefs(useStore());
const { load_background_datas_from_api } = useStore();

const show_modal = ref(false);
const is_sent = ref(false);
const message = ref("");
const isProduction = ref(false);

const show_header = computed(() => {
  return (
    !route.meta.is_offline_page &&
    !(
      route.meta.offline_access
      // && !this.$root.is_online
    )
  );
});

const background_initial_datas = async function () {
  const status: string = await load_background_datas_from_api($api);
  if (status === "success") {
    $bus.emit("init_bar_chart");
    $bus.emit("init_bills_results");
    $bus.emit("init_consumptions_results");
  } else if (status === "unknown")
    console.log("background datas download error...");
};

const display_modal = function () {
  show_modal.value = !show_modal.value;
  ticket.value = null;
};

const display_confirm = function (value: boolean) {
  is_sent.value = true;
  if (value) {
    message.value = "Votre demande a bien été prise en compte.";
  } else {
    message.value =
      "Erreur lors de l'enregistrement ou l'envoi de votre demande";
  }
};
const go_to_requests = function () {
  is_sent.value = false;
  router.push("/requests");
};

const visited_pages: Ref<Array<any>> = ref([]);

watch(
  () => route,
  (from) => {
    if (!from.meta.is_offline_page) {
      visited_pages.value.push({
        name: from.name,
        title: from.meta.title,
        fullpath: from.fullPath,
      });
      if (visited_pages.value.length > 1) {
        last_visited_pages.value.push(visited_pages.value.slice(-2, -1).pop());
        if (last_visited_pages.value.length > 3) last_visited_pages.value.shift();
      }
    }
  }
);

onMounted(() => {
  isProduction.value = window.location.hostname === "clients.gaz-europeen.com";
  $bus.on("load_background_initial_datas", background_initial_datas);
});

return (_ctx: any,_cache: any) => {
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_metainfo, null, {
      title: _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? `${content} | Gaz Européen` : `Gaz Européen`), 1)
      ]),
      _: 1
    }),
    (show_header.value)
      ? (_openBlock(), _createBlock(TopBar, { key: 0 }))
      : _createCommentVNode("", true),
    (show_header.value)
      ? (_openBlock(), _createBlock(MainNavigation, { key: 1 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      id: "content",
      class: _normalizeClass({ hide_header: !show_header.value })
    }, [
      (_openBlock(), _createBlock(_component_router_view, {
        key: _ctx.$route.fullPath
      }))
    ], 2),
    _createVNode(BottomBar),
    (show_header.value && _unref(ticket_access) && isProduction.value)
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          class: "floating_button button_new_request",
          onClick: display_modal
        }, _cache[4] || (_cache[4] = [
          _createElementVNode("span", { class: "icon icon-question" }, null, -1),
          _createElementVNode("span", null, "Nouvelle demande", -1)
        ])))
      : _createCommentVNode("", true),
    (show_header.value && show_modal.value)
      ? (_openBlock(), _createBlock(FullPageModal, {
          key: 3,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (show_modal.value = !show_modal.value))
        }, {
          body: _withCtx(() => [
            _createVNode(FormRequest, {
              onClose: _cache[0] || (_cache[0] = ($event: any) => (show_modal.value = !show_modal.value)),
              onConfirm: _cache[1] || (_cache[1] = ($event: any) => (display_confirm($event))),
              name: 'Nouvelle demande'
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (show_header.value && is_sent.value)
      ? (_openBlock(), _createBlock(BaseModal, {
          key: 4,
          class: "modal-information"
        }, {
          body: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", _hoisted_3, _toDisplayString(message.value), 1),
              _createElementVNode("button", {
                class: "modal-button button_new_request",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (is_sent.value = false))
              }, " Fermer "),
              _createElementVNode("button", {
                class: "router-link",
                onClick: go_to_requests
              }, " Voir mes demandes ")
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(BaseLoader)
  ]))
}
}

})