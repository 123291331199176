<script setup lang="ts">
import { inject, onMounted, ref } from "vue";

const loader_state = ref("hide");
const loader_slideshow = ref(false);
const is_long_request = ref(false);
const slideshow_index = ref(1);
const slideshow_max_items = ref(5);
const loader_download_target = ref(0);
const loader_download_count = ref(0);
let slideshow_interval: any = null;

const reset_loader_download = function () {
  loader_download_target.value = 0;
  loader_download_count.value = 0;
};

const trigger_slideshow = function (init = false) {
  if (slideshow_index.value === slideshow_max_items.value)
    slideshow_index.value = 1;
  else if (!init) slideshow_index.value++;
};

const launch_interval = function () {
  trigger_slideshow(true);
  slideshow_interval = setInterval(() => {
    trigger_slideshow();
  }, 5000);
};

const $bus = inject("bus");

onMounted(() => {
  $bus.on("loader", (state: string) => {
    loader_state.value = state;
    reset_loader_download();
  });

  $bus.on("loader_slideshow", (state: boolean) => {
    slideshow_index.value = 1;
    if (state) launch_interval();
    else clearInterval(slideshow_interval);
    loader_slideshow.value = state;
  });

  $bus.on("loader_download_target", (value: number) => {
    loader_download_target.value = value;
  });

  $bus.on("loader_is_long_request", (value: boolean) => {
    is_long_request.value = value;
  });

  $bus.on("loader_download_count", (value: number) => {
    loader_download_count.value = value;
  });
});
</script>

<template>
  <div id="loader" :class="loader_state">
    <div id="loader_wrapper" :class="{ with_slideshow: loader_slideshow }">
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
      <transition name="fade">
        <p class="long-request" v-if="is_long_request">
          Nous récupérons des données très volumineuses, pour optimiser<br />
          le temps de chargement, pensez à réduire la plage de recherche
        </p>
      </transition>
      <div class="download_count" v-if="loader_download_target > 0">
        Téléchargement en cours<br />
        <span>{{ loader_download_count }} / {{ loader_download_target }}</span>
      </div>
      <div class="loader_slideshow" v-if="loader_slideshow">
        <transition-group name="slide_left">
          <span
            class="slide"
            v-show="slideshow_index === n"
            :key="n"
            v-for="n in slideshow_max_items"
          >
            <img :src="'/loading_slideshow/' + n + '.png'" alt="" />
          </span>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
#loader {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  animation-duration: 0.5s;
  animation-name: fade_appear;

  &.show {
    display: block;
  }

  #loader_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100vh;

    &.with_slideshow {
      margin-top: -6%;
    }

    .download_count {
      color: $white;
      font-size: 16px;
      margin-top: 10px;
      text-align: center;

      span {
        display: block;
        font-size: 20px;
        margin-top: 7px;
        font-family: monospace, serif;
      }
    }

    .long-request {
      color: $white;
      font-size: 18px;
      margin-top: 10px;
      text-align: center;
    }

    .loader_slideshow {
      position: relative;
      margin-top: 20px;
      width: 100%;
      text-align: center;
      animation-name: fade_appear;
      animation-duration: 0.6s;

      .slide {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 0 20px;
        color: $white;
        font-size: 18px;
        line-height: 22px;
        text-shadow: #000 2px 0 13px;

        img {
          max-width: 100%;
        }
      }
    }
  }
}
</style>
