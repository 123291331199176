<script setup lang="ts"></script>

<template>
  <footer id="bottom_bar">
    <router-link to="/legal-notice" data-tracking="footer__link__legal_notice">
      Mentions légales
    </router-link>
    <router-link
      to="/privacy-policy"
      data-tracking="footer__link__privacy_policy"
    >
      Politique de confidentialité
    </router-link>
    <a href="#" id="tarteaucitronManager"> Cookies </a>
  </footer>
</template>

<style scoped lang="scss">
#bottom_bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  background-color: $white;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 1;

  a {
    font-size: 13px;
    font-weight: 500;
    margin: 0 20px;

    @media #{$phone} {
      font-size: 12px;
      margin: 0 10px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
