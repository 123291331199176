import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "main_navigation" }
const _hoisted_2 = { class: "sr-only" }

import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useStore } from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'MainNavigation',
  setup(__props) {

const { ticket_access, account, sites } = storeToRefs(useStore());

const menu_open = ref(false);
const isProduction = ref(false);

onMounted(() => {
  isProduction.value = window.location.hostname === "clients.gaz-europeen.com";
});
const hide_mobile_menu = function () {
  menu_open.value = false;
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", {
      id: "burger_menu_trigger",
      class: _normalizeClass({ open: menu_open.value }),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (menu_open.value ? (menu_open.value = false) : (menu_open.value = true)))
    }, [
      _cache[1] || (_cache[1] = _createElementVNode("span", null, null, -1)),
      _cache[2] || (_cache[2] = _createElementVNode("span", null, null, -1)),
      _cache[3] || (_cache[3] = _createElementVNode("span", null, null, -1)),
      _cache[4] || (_cache[4] = _createElementVNode("span", null, null, -1)),
      _createElementVNode("em", _hoisted_2, _toDisplayString(menu_open.value ? "Fermer le menu principal" : "Ouvrir le menu principal"), 1)
    ], 2),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["nav_link_container", { menu_is_open: menu_open.value }])
        }, [
          _createVNode(_component_router_link, {
            class: "nav_link",
            to: "/home",
            onClick: hide_mobile_menu,
            "data-tracking": "header__link__navigation_home"
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createElementVNode("span", null, " Accueil ", -1)
            ])),
            _: 1
          }),
          (_unref(sites)?.length > 1)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                class: _normalizeClass(["nav_link", { force_active: _ctx.$route.meta.nav_search_active }]),
                to: "/search",
                onClick: hide_mobile_menu,
                "data-tracking": "header__link__navigation_search"
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createElementVNode("span", null, " Mes sites ", -1)
                ])),
                _: 1
              }, 8, ["class"]))
            : _createCommentVNode("", true),
          _createVNode(_component_router_link, {
            class: _normalizeClass(["nav_link", { with_alert: _unref(account)?.total_very_late > 0 }]),
            to: "/bills",
            onClick: hide_mobile_menu,
            "data-tracking": "header__link__navigation_my_bills"
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createElementVNode("span", null, " Mes factures ", -1)
            ])),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_router_link, {
            class: "nav_link",
            to: "/consumptions",
            onClick: hide_mobile_menu,
            "data-tracking": "header__link__navigation_my_consumptions"
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createElementVNode("span", null, " Mes consommations ", -1)
            ])),
            _: 1
          }),
          (_unref(ticket_access) && isProduction.value)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                class: "nav_link",
                to: "/requests",
                onClick: hide_mobile_menu,
                "data-tracking": "header__link__navigation_my_requests"
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createElementVNode("span", null, " Mes demandes ", -1)
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_router_link, {
            class: _normalizeClass(["nav_link", { force_active: _ctx.$route.meta.nav_actuality_active }]),
            to: "/actualities",
            onClick: hide_mobile_menu,
            "data-tracking": "header__link__navigation_news"
          }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [
              _createElementVNode("span", null, " Actualités ", -1)
            ])),
            _: 1
          }, 8, ["class"])
        ], 2)
      ]),
      _: 1
    })
  ]))
}
}

})