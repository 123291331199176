<script setup lang="ts">
import { computed, defineProps, Ref, ref, defineEmits } from "vue";
import { storeToRefs } from "pinia";
import { useStore } from "@/store";

defineProps({
  name: {
    type: String,
    required: false,
  },
});

const emit = defineEmits(["close", "confirm"]);

const {
  request_data,
  ticket,
  site_pce_list,
  site_prm_list,
  account,
  user_id,
  fields,
} = storeToRefs(useStore());

const request_type_index: Ref<number> = ref(-1);
const request_id = ref(null);
const energy_id = ref(null);
const sell_points = ref(null);

const request_form = computed(() => {
  if (request_type_index.value >= 0) {
    return request_data.value[request_type_index.value];
  } else if (ticket.value?.request) {
    return request_data.value[ticket.value.request.id - 1];
  } else return { error: "no request selected" };
});

const request_name = computed(() => {
  if (ticket.value?.request) return ticket.value.request.name;
  return null;
});

const request_data_filtered = computed(() => {
  let res: any[] = [];
  request_data.value.forEach((form) => {
    res.push(form);
  });

  return res;
});

const energy = computed(() => {
  if (ticket.value.energy) return ticket.value.energy.id;
  return energy_id.value;
});

const sell_point_options = computed(() => {
  if (energy.value === 2) return site_pce_list.value;
  else if (energy.value === 1) return site_prm_list.value;
  else return [];
});

const refresh_form = function (request_name: string) {
  request_type_index.value = request_data.value.findIndex(
    (request) => request.name === request_name
  );
  request_id.value = request_form.value.id;
  if (request_type_index.value !== -1) {
    let payload = {};
    // payload.request_type = this.request_form.id;
    // payload.energy_id = this.energy_id;
    request_form.value.groups.forEach((group: any) => {
      group.fields.forEach((field: any) => {
        if (field.name) {
          Object.assign(payload, { [field.name]: null });
        }
      });
    });
    if (request_form.value.fields) {
      request_form.value.fields.forEach((field: any) => {
        if (field.name) {
          Object.assign(payload, { [field.name]: null });
        }
      });
    }
    ticket.value = payload;
  }
};
const submit_form = function () {
  // this.$bus.$emit('loader', 'show');
  let formData = new FormData();
  if (user_id.value) formData.append("user_id", user_id.value);
  let payload = {};
  if (ticket.value.id) {
    formData.append("request_id", ticket.value.request.id);
    formData.append("energy_id", ticket.value.energy.id);
    if (sell_points.value)
      formData.append("delivery_points", sell_points.value);
    formData.append("parent_id", ticket.value.id);
    Object.keys(ticket.value.request_datas).forEach((key) => {
      if (key.indexOf("file") !== -1) {
        formData.append(key, ticket.value.request_datas[key]);
      } else Object.assign(payload, { [key]: ticket.value.request_datas[key] });
    });
  } else {
    if (request_id.value) formData.append("request_id", request_id.value);
    if (energy_id.value) formData.append("energy_id", energy_id.value);
    if (sell_points.value)
      formData.append("delivery_points", sell_points.value);
    if (account.value) {
      let commercial = account.value.commercial_contact_name.split(" ");
      formData.append("commercial_firstname", commercial[0]);
      formData.append("commercial_lastname", commercial[1]);
      formData.append(
        "commercial_email",
        account.value.commercial_contact_email
      );
    }

    let input = null;
    Object.keys(ticket.value).forEach((key) => {
      input = fields.value.find((x) => x.name === key);
      if (input.type === "file" && ticket.value[key]) {
        console.log(key.indexOf("file"));
        formData.append(key, ticket.value[key], ticket.value[key].name);
      } else
        Object.assign(payload, {
          [key]: ticket.value[key],
        });
    });
  }
  formData.append("request_datas", JSON.stringify(payload));
  // post_new_request(formData)
  //   .then((response) => {
  //     if (response.data.status === "success") {
  //       let datas = null;
  //       if (Array.isArray(response.data.datas)) {
  //         datas = response.data.datas[0];
  //       } else datas = response.data.datas;
  //       if (!ticket.value.id) ticket.value = datas.ticket;
  //       setTimeout(() => {
  //         //   this.$bus.$emit("loader", "hide");
  //         emit("close");
  //       }, 500);
  //
  //       emit("confirm", "update");
  //       // return datas;
  //     } else emit("confirm", "error");
  //   })
  //   .catch((error: any) => {
  //     console.error(error);
  //     // this.$bus.$emit("loader", "hide");
  //     emit("confirm", "error");
  //   });
};
</script>

<template>
  <div class="form_container">
    <h2 class="orange_title">{{ name }}</h2>
    <form
      id="new_request_form"
      :ref="request_form.name"
      @submit.prevent="submit_form"
    >
      <form-select-component
        :select_data="{
          name: 'request_type',
          label: 'Type de demande',
          required: true,
          editable: true,
          options: request_data_filtered,
          value: request_name,
          no_label: true,
        }"
        v-on:switch-request-form="refresh_form($event)"
      ></form-select-component>
      <!--      <template v-if="request_form.error">
                                {{ request_form.error }}
                              </template>-->
      <template v-if="!request_form.error">
        <div class="formline">
          <form-switch-component
            :switch_data="{ name: 'energy_type', value: energy }"
            v-on:choose-energy="energy_id = $event"
          ></form-switch-component>
          <form-select-component
            :select_data="{
              name: 'sell_points',
              label: 'Point de livraison',
              required: true,
              editable: true,
              options: sell_point_options,
              no_label: true,
              multiple: true,
            }"
            v-on:update-sell-points="sell_points = $event"
          ></form-select-component>
        </div>
        <section v-for="(group, index) in request_form.groups" :key="index">
          <template v-if="group.name === 'customer_delivery_fields'">
            <form-group-address-component
              :name="group.name"
              :group_title="group.label"
              :fields="group.fields"
              :energy="energy"
            ></form-group-address-component>
          </template>
          <template v-else-if="group.name === 'technical_contact_fields'">
            <form-group-interlocutor-component
              :name="group.name"
              :group_title="group.label"
              :fields="group.fields"
              :energy="energy"
            ></form-group-interlocutor-component>
          </template>
          <template v-else-if="group.name === 'gaz_precisions_fields'">
            <h2 class="group_title" v-if="energy === 2">{{ group.label }}</h2>
            <form-group-wrapper-component
              :name="group.name"
              :fields="group.fields"
              :energy="energy"
            ></form-group-wrapper-component>
          </template>
          <template v-else>
            <h2 class="group_title">{{ group.label }}</h2>
            <div class="groupfield otherfields">
              <template v-for="(field, index) in group.fields">
                <form-select-component
                  v-if="
                    field.type === 'select' &&
                    (field.energy_id === energy || !field.energy_id)
                  "
                  :class="[field.name, { long_field: field.label.length > 20 }]"
                  :select_data="field"
                  :key="`field_${field.name}_${index}`"
                ></form-select-component>
                <form-radio-component
                  v-else-if="
                    field.type === 'radio' &&
                    (field.energy_id === energy || !field.energy_id)
                  "
                  :class="[field.name, { long_field: field.label.length > 20 }]"
                  :radio_data="field"
                  :key="`field_${field.name}_${index}`"
                ></form-radio-component>
                <form-checkbox-component
                  v-else-if="
                    field.type === 'checkbox' &&
                    (field.energy_id === energy || !field.energy_id)
                  "
                  :class="[field.name, { long_field: field.label.length > 40 }]"
                  :checkbox_data="field"
                  :key="`field_${field.name}_${index}`"
                ></form-checkbox-component>
                <form-file-component
                  v-else-if="
                    field.type === 'file' &&
                    (field.energy_id === energy || !field.energy_id)
                  "
                  :class="[field.name, { long_field: field.label.length > 40 }]"
                  :input_data="field"
                  :key="`field_${field.name}_${index}`"
                ></form-file-component>
                <form-textarea-component
                  v-else-if="
                    field.type === 'textarea' &&
                    (field.energy_id === energy || !field.energy_id)
                  "
                  :class="[field.name, { long_field: field.label.length > 40 }]"
                  :text_data="field"
                  :key="`field_${field.name}_${index}`"
                ></form-textarea-component>
                <form-input-component
                  v-else-if="field.energy_id === energy || !field.energy_id"
                  :class="[field.name, { long_field: field.label.length > 40 }]"
                  :input_data="field"
                  :key="`field_${field.name}_${index}`"
                ></form-input-component>
              </template>
            </div>
          </template>
        </section>
        <!-- @todo possibilité de rajouter des champs libres de tout groupe -->
        <div class="otherfields" v-if="request_form.fields">
          <template v-for="(field, index) in request_form.fields">
            <form-select-component
              v-if="
                field.type === 'select' &&
                (field.energy_id === energy || !field.energy_id)
              "
              :class="[field.name, { long_field: field.label.length > 20 }]"
              :select_data="field"
              :key="`field_${field.name}_${index}`"
            ></form-select-component>
            <form-radio-component
              v-else-if="
                field.type === 'radio' &&
                (field.energy_id === energy || !field.energy_id)
              "
              :class="[field.name, { long_field: field.label.length > 40 }]"
              :radio_data="field"
              :key="`field_${field.name}_${index}`"
            ></form-radio-component>
            <form-checkbox-component
              v-else-if="
                field.type === 'checkbox' &&
                (field.energy_id === energy || !field.energy_id)
              "
              :class="[field.name, { long_field: field.label.length > 40 }]"
              :checkbox_data="field"
              :key="`field_${field.name}_${index}`"
            ></form-checkbox-component>
            <form-file-component
              v-else-if="
                field.type === 'file' &&
                (field.energy_id === energy || !field.energy_id)
              "
              :class="[field.name, { long_field: field.label.length > 40 }]"
              :input_data="field"
              :key="`field_${field.name}_${index}`"
            ></form-file-component>
            <form-textarea-component
              v-else-if="
                field.type === 'textarea' &&
                (field.energy_id === energy || !field.energy_id)
              "
              :class="[field.name, { long_field: field.label.length > 40 }]"
              :text_data="field"
              :key="`field_${field.name}_${index}`"
            ></form-textarea-component>
            <form-input-component
              v-else-if="field.energy_id === energy || !field.energy_id"
              :class="[field.name, { long_field: field.label.length > 40 }]"
              :input_data="field"
              :key="`field_${field.name}_${index}`"
            ></form-input-component>
          </template>
        </div>
        <button type="submit" class="button button_submit" v-if="ticket.id">
          Enregistrer mes modifications
        </button>
        <button type="submit" class="button button_submit" v-else>
          Envoyer ma demande
        </button>
      </template>
    </form>
  </div>
</template>

<style scoped lang="scss">
.form_container {
  max-width: 540px;
  margin: auto;
}

.orange_title {
  @include font_h1;
  color: $pinkish_orange;
  margin-bottom: 26px;
  text-align: center;
}

form {
  margin-bottom: 40px;

  .formline {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .group_title {
    color: $dodger_blue;
    font-family: $font_sailec;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    margin-bottom: 24px;
  }

  .otherfields .fieldline {
    max-width: 70%;
    align-items: center;

    &.long_field {
      max-width: 100%;
    }
  }

  .button_submit {
    border-radius: 2px;
    background-color: $dodger_blue;
    color: #ffffff;
    padding: 15px 21px;
    cursor: pointer;

    &:hover {
      background-color: $bright_blue;
    }
  }
}
</style>
