export default function (phone_number: string) {
  if (phone_number.trim().indexOf(" ") === -1) {
    const phone_number_split = phone_number.trim().split("");
    let phone_number_formated = "";
    phone_number_split.forEach((number, index) => {
      if (index === 0 || index % 2 === 0) phone_number_formated += " ";
      phone_number_formated += number;
    });
    return phone_number_formated;
  } else {
    return phone_number;
  }
}
