import { defineStore } from "pinia";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import request_data_file from "../../public/requests.json";
import { Ref, ref } from "vue";
import striptags from "striptags";
import ws2string from "@/utils/ws2string";
import ws2float from "@/utils/ws2float";
import ws2int from "@/utils/ws2int";
import generateSiteAddress from "@/utils/generateSiteAddress";
import clean_ws_date from "@/utils/clean_ws_date";
import defaultBillsFilters from "@/utils/defaultBillsFilters";
import jsonToWsFilters from "@/utils/jsonToWsFilters";
import defaultConsumptionsFilters from "@/utils/defaultConsumptionsFilters";

export const useStore = defineStore(
  "store",
  () => {
    const email: Ref<string> = ref("");
    const token: Ref<string> = ref("");
    const user_id: Ref<string> = ref("");
    const ticket_access: Ref<boolean> = ref(false);
    const login_timestamp: Ref<number> = ref(0);
    const actualities: Ref<any> = ref([]);
    const account: Ref<any> = ref(null);
    const sites: Ref<Array<any>> = ref([]);
    const sites_default_datas: Ref<Array<any>> = ref([]);
    const default_circle_chart: Ref<any> = ref(null);
    const default_bar_chart: Ref<any> = ref(null);
    const default_bills: Ref<any> = ref(null);
    const default_consumptions: Ref<any> = ref(null);
    const global_has_pce: Ref<boolean> = ref(false);
    const global_has_prm: Ref<boolean> = ref(false);
    const site_name_list: Ref<any> = ref(null);
    const site_code_list: Ref<any> = ref(null);
    const site_address_list: Ref<any> = ref(null);
    const site_pce_list: Ref<any> = ref(null);
    const site_prm_list: Ref<any> = ref(null);
    const show_banner: Ref<boolean> = ref(true);
    const request_data: Ref<any[]> = ref([]);
    const status: Ref<any[]> = ref([]);
    const options: Ref<any[]> = ref([]);
    const fields: Ref<any[]> = ref([]);
    const energies_data: Ref<any> = ref(null);
    const tickets: Ref<any[]> = ref([]);
    const ticket: Ref<any> = ref(null);
    const last_visited_pages: Ref<any[]> = ref([]);
    const monthStart = ref("");
    const yearStart = ref("");
    const monthEnd = ref("");
    const yearEnd = ref("");

    const site_default_datas = (id: string) => {
      return sites_default_datas.value.find((x: any) => x.id == id);
    };

    const set_login_infos = (datas: {
      email: string;
      token: string;
      user_id?: string;
      ticket_access?: boolean;
      login_timestamp: number;
    }) => {
      email.value = datas.email;
      token.value = datas.token;
      user_id.value = datas.user_id || "";
      ticket_access.value = datas.ticket_access || false;
      login_timestamp.value = datas.login_timestamp;
    };
    const set_request_infos = (datas = null) => {
      request_data.value = datas || request_data_file["datas"];
    };

    const get_datas_from_ws_response = function (
      type: string,
      results: Array<any>
    ) {
      const datas: Array<any> = [];
      if (results !== undefined) {
        if (!Array.isArray(results)) results = [results];
        if (results.length) {
          results.forEach((result) => {
            const item_data = result.content["m:properties"];

            if (type === "circle_chart") {
              datas.push({
                energy: item_data["d:Energie"] === "E" ? "electricity" : "gas",
                consumption: ws2float(item_data["d:Consommation"]),
                date_start_month: ws2string(item_data["d:MoisDebut"]),
                date_start_year: ws2string(item_data["d:AnneeDebut"]),
                date_end_month: ws2string(item_data["d:MoisFin"]),
                date_end_year: ws2string(item_data["d:AnneeFin"]),
                total_consumption: ws2float(item_data["d:MontantHtConso"]),
                total_tax: ws2float(item_data["d:MontantHTTax"]),
                total_subscription: ws2float(item_data["d:MontantHTAbo"]),
                total_prestation: ws2float(item_data["d:MontantHTPrest"]),
                total: ws2float(item_data["d:MontantTTC"]),
              });
            } else if (type === "bar_chart") {
              datas.push({
                energy: item_data["d:Energie"] === "E" ? "electricity" : "gas",
                type: item_data["d:ConsoType"] === "E" ? "estimated" : "real",
                year: ws2string(item_data["d:ConsoAnnee"]),
                month: ws2string(item_data["d:ConsoMois"]),
                consumption: ws2float(item_data["d:ConsoTotalKwh"]),
              });
            } else if (type === "bills") {
              datas.push({
                energy: item_data["d:Energie"] === "E" ? "electricity" : "gas",
                id: ws2string(item_data["d:FactureNumero"]),
                status: ws2int(item_data["d:FactureStatut"]),
                deadline: clean_ws_date(item_data["d:FactureEcheance"]),
                date: clean_ws_date(item_data["d:FactureDate"]),
                price: ws2float(item_data["d:MontantHt"]),
                customer_name: ws2string(item_data["d:NomClient"]),
                customer_billing_name: ws2string(
                  item_data["d:NomClientFacture"]
                ),
                customer_reference: ws2string(item_data["d:REF"]),
                date_start: clean_ws_date(item_data["d:PeriodeDu"]),
                date_end: clean_ws_date(item_data["d:PeriodeAu"]),
                date_month: ws2string(item_data["d:MoisConso"]),
                date_year: ws2string(item_data["d:AnneeConso"]),
                consumption: ws2float(item_data["d:consommationMWh"], true),
                total_subscription: ws2float(item_data["d:TotalHTAbo"]),
                total_energy: ws2float(item_data["d:TotalHTEnergie"]),
                total_cta: ws2float(item_data["d:TotalHTCta"]),
                consumption_ticgn: ws2float(
                  item_data["d:consommationTicgn"],
                  true
                ),
                total_ticgn: ws2float(item_data["d:TotalHTTicgn"]),
                total_prestation: ws2float(item_data["d:TotalHTPrestation"]),
                tva_tr: ws2float(item_data["d:TvaTR"]),
                tva_tp: ws2float(item_data["d:TvaTP"]),
                total_tva: ws2float(item_data["d:TotalTva"]),
                total_cspe: ws2float(item_data["d:TotalHTCspe"]),
                total_tcfe: ws2float(item_data["d:TotalHTTcfe"]),
                total_cbm: ws2float(item_data["d:TotalHTCbm"]),
                total_ctss: ws2float(item_data["d:TotalHTCTSS"]),
                total_ht: ws2float(item_data["d:TotalHT"]),
                total: ws2float(item_data["d:TotalTTC"]),
                payment_code: ws2string(item_data["d:CodeReglement"]),
                payment_method: ws2string(item_data["d:ModeReglement"]),
                is_multiple: item_data["d:FactureMultisite"].length > 0,
                pdl_list: ws2string(item_data["d:ListePDL"]),
                site_name: ws2string(item_data["d:ListeNomSite"]),
                site_code: ws2string(item_data["d:ListeSiteCodeInterne"]),
                site_address: ws2string(item_data["d:ListeAdresse"]),
                site_total: ws2string(item_data["d:ListeMontantTTC"]),
              });
            } else if (type === "consumptions") {
              // Pour les consommations en électricité on ne garde pour le moment que les valeurs ayant "EA" dans la colonne "Cadran"
              if (
                item_data["d:Energie"] === "G" ||
                (item_data["d:Energie"] === "E" &&
                  ws2string(item_data["d:Cadran"]).indexOf("EA") !== -1)
              ) {
                datas.push({
                  id: ws2string(item_data["d:ReleveId"]),
                  energy:
                    item_data["d:Energie"] === "E" ? "electricity" : "gas",
                  pdl: ws2string(item_data["d:Pdl"]),
                  date_start: clean_ws_date(item_data["d:ReleveDebut"]),
                  date_end: clean_ws_date(item_data["d:ReleveFin"]),
                  site_name: ws2string(item_data["d:SiteNom"]),
                  site_code: ws2string(item_data["d:SiteCodeInterne"]),
                  address: ws2string(item_data["d:SiteAdresse"]),
                  consumption: ws2float(
                    item_data["d:ConsommationKwh"],
                    item_data["d:Energie"] === "G"
                  ), // Si gaz on divise par 1000
                  customer_name: ws2string(item_data["d:NomClient"]),
                  customer_billing_name: ws2string(
                    item_data["d:NomClientFacture"]
                  ),
                  customer_reference: ws2string(item_data["d:Ref"]),
                  meter_number: ws2string(item_data["d:MatriculeCompteur"]),
                  reason: ws2string(item_data["d:RaisonReleve"]),
                  index_start: ws2string(item_data["d:IndexDebut"]),
                  index_end: ws2string(item_data["d:IndexFin"]),
                  volume_m3: ws2string(item_data["d:VolumeM3"]),
                  volume_nm3: ws2string(item_data["d:VolumeNM3"]),
                  bill_number: ws2string(item_data["d:FactureNumero"]),
                  cadran: ws2string(item_data["d:Cadran"]),
                  ps: ws2string(item_data["d:PS"]),
                  tr: ws2string(item_data["d:TR"]),
                  coef_pta: ws2string(item_data["d:Coef_PTA"]),
                  coef_pcs: ws2string(item_data["d:Coef_PCS"]),
                });
              }
            }
          });
        }
      }
      return datas;
    };
    const load_datas_from_api = async ($api: any) => {
      const currentDate = new Date();
      monthStart.value = ("0" + (currentDate.getMonth() + 1)).slice(-2);
      yearStart.value = currentDate.getFullYear().toString();
      monthEnd.value = monthStart.value;
      yearEnd.value = currentDate.getFullYear().toString();

      monthStart.value = ("0" + currentDate.getMonth()).slice(-2);
      //yearStart = currentDate.getFullYear();
      if (monthStart.value === "00") {
        monthStart.value = "12";
        monthEnd.value = "01";
        yearStart.value = (currentDate.getFullYear() - 1).toString();
      } else monthEnd.value = ("0" + (currentDate.getMonth() + 1)).slice(-2);
      yearEnd.value = currentDate.getFullYear().toString();

      const filters = {
        MoisDebut: monthStart.value,
        AnneeDebut: yearStart.value,
        MoisFin: monthEnd.value,
        AnneeFin: yearEnd.value,
      };

      try {
        const datas = await Promise.all([
          $api.actualities.getActualities(),
          $api.account.getAccountInformation(email.value),
          $api.account.getSites(email.value),
          $api.graphs.getCamenbert(email.value, jsonToWsFilters(filters)),
          $api.request.getRequestList(),
        ]);
        if (datas[0]?.status === "success") {
          const datasResults: Array<any> = [];

          datas[0].datas.forEach((item_data: any) => {
            datasResults.push({
              id: item_data.id,
              homepage_push: item_data.homepage_push,
              date: item_data.date,
              title: item_data.title,
              main_picture: item_data.main_picture,
              main_picture_width: item_data.main_picture_width,
              main_picture_height: item_data.main_picture_height,
              content_stripped: striptags(item_data.content),
              content: item_data.content,
            });
          });
          actualities.value = datasResults;
        } else {
          return "actualities_error";
        }

        if (datas[1]?.result?.content !== undefined) {
          const item_data: any = datas[1].result.content["m:properties"];
          account.value = {
            email: ws2string(item_data["d:LoginEmail"]),
            name: ws2string(item_data["d:LoginNom"]),
            name_group: ws2string(item_data["d:LoginPerimetre"]),
            address: ws2string(item_data["d:LoginAdresse"]),
            phone: ws2string(item_data["d:LoginTelephone"]),
            fax: ws2string(item_data["d:LoginFax"]),
            total_to_pay: ws2float(item_data["d:TotalAPayer"]),
            total_late: ws2float(item_data["d:TotalRetard"]),
            total_very_late: ws2float(item_data["d:TotalRetard90"]),
            bills_to_pay_count: ws2string(item_data["d:NombreFacturesAPayer"]),
            site_count: ws2string(item_data["d:NombreSites"]),
            pce_count: ws2string(item_data["d:NombrePce"]),
            prm_count: ws2string(item_data["d:NombrePrm"]),
            commercial_contact_name: ws2string(item_data["d:CommercialNom"]),
            commercial_contact_phone: ws2string(
              item_data["d:CommercialTelephone"]
            ),
            commercial_contact_email: ws2string(item_data["d:CommercialEmail"]),
            billing_contact_name: ws2string(item_data["d:FacturationNom"]),
            billing_contact_phone: ws2string(
              item_data["d:FacturationTelephone"]
            ),
          };
        } else {
          return "account_error";
        }

        if (!datas[2]?.error) {
          const datasResults: Array<any> = [];
          const promises = [];
          let response_results = [];

          // Si 1 seul site on a un objet en retour et non un array, on lise donc l'ensemble pour retourner un tableau dans tous les cas
          if (Array.isArray(datas[2]?.result.entry))
            response_results = datas[2]?.result.entry;
          else response_results.push(datas[2]?.result.entry);

          response_results.forEach((site: any) => {
            const item_data = site.content["m:properties"];
            //var full_address = generateSiteAddress(ws2string(item_data['d:SiteRueNumero']), ws2string(item_data['d:SiteRueNom']), ws2string(item_data['d:SiteCodePostal']), ws2string(item_data['d:SiteVille']));
            const coordinates = site.coord;

            if (ws2int(item_data["d:NombrePce"]) > 0) {
              global_has_pce.value = true;
            }

            if (ws2int(item_data["d:NombrePrm"]) > 0) {
              global_has_prm.value = true;
            }

            const site_pces = ws2string(item_data["d:PCE"]);
            const site_pces_array: any[] = [];
            if (site_pces && site_pces.length > 0) {
              site_pces.split(";").forEach((site_pce) => {
                if (site_pce && site_pces_array.indexOf(site_pce) === -1)
                  site_pces_array.push(site_pce);
              });
            }

            const site_prms = ws2string(item_data["d:PRM"]);
            const site_prms_array: any[] = [];
            if (site_prms && site_prms.length > 0) {
              site_prms.split(";").forEach((site_prm) => {
                site_prms_array.push(site_prm);
              });
            }

            const site_prms_ps = ws2string(item_data["d:PS"]);
            const site_prms_ps_array: Array<any> = [];
            if (site_prms_ps && site_prms_ps.length > 0) {
              site_prms_ps.split(";").forEach((site_prm_ps) => {
                // site_prms_ps_array.push(ws2float(site_prm_ps).replace('.00','') + ' kW');
                site_prms_ps_array.push(
                  ws2float(site_prm_ps).replace(".00", "")
                );
              });
            }

            const site_prms_pspointe = ws2string(item_data["d:PSPointe"]);
            const site_prms_pspointe_array: Array<any> = [];
            if (site_prms_pspointe && site_prms_pspointe.length > 0) {
              site_prms_pspointe.split(";").forEach((site_prm_pspointe) => {
                // site_prms_pspointe_array.push(ws2float(site_prm_pspointe).replace('.00','') + ' kW');
                site_prms_pspointe_array.push(
                  ws2float(site_prm_pspointe).replace(".00", "")
                );
              });
            }

            const site_prms_pshph = ws2string(item_data["d:PSHPH"]);
            // console.log('site_prms_pshph');
            // console.log(site_prms_pshph);
            const site_prms_pshph_array: Array<any> = [];
            if (site_prms_pshph && site_prms_pshph.length > 0) {
              site_prms_pshph.split(";").forEach((site_prm_pshph) => {
                // site_prms_pshph_array.push(ws2float(site_prm_pshph).replace('.00','') + ' kW');
                site_prms_pshph_array.push(
                  ws2float(site_prm_pshph).replace(".00", "")
                );
              });
            }

            const site_prms_pshch = ws2string(item_data["d:PSHCH"]);
            const site_prms_pshch_array: Array<any> = [];
            if (site_prms_pshch && site_prms_pshch.length > 0) {
              site_prms_pshch.split(";").forEach((site_prm_pshch) => {
                // site_prms_pshch_array.push(ws2float(site_prm_pshch).replace('.00','') + ' kW');
                site_prms_pshch_array.push(
                  ws2float(site_prm_pshch).replace(".00", "")
                );
              });
            }

            const site_prms_pshpe = ws2string(item_data["d:PSHPE"]);
            const site_prms_pshpe_array: Array<any> = [];
            if (site_prms_pshpe && site_prms_pshpe.length > 0) {
              site_prms_pshpe.split(";").forEach((site_prm_pshpe) => {
                // site_prms_pshpe_array.push(ws2float(site_prm_pshpe).replace('.00','') + ' kW');
                site_prms_pshpe_array.push(
                  ws2float(site_prm_pshpe).replace(".00", "")
                );
              });
            }

            const site_prms_pshce = ws2string(item_data["d:PSHCE"]);
            const site_prms_pshce_array: Array<any> = [];
            if (site_prms_pshce && site_prms_pshce.length > 0) {
              site_prms_pshce.split(";").forEach((site_prm_pshce) => {
                // site_prms_pshce_array.push(ws2float(site_prm_pshce).replace('.00','') + ' kW');
                site_prms_pshce_array.push(
                  ws2float(site_prm_pshce).replace(".00", "")
                );
              });
            }

            const site_prms_segment = ws2string(item_data["d:Segment"]);
            const site_prms_segment_array: Array<any> = [];
            if (site_prms_segment && site_prms_segment.length > 0) {
              site_prms_segment.split(";").forEach((site_prm_segment) => {
                site_prms_segment_array.push(site_prm_segment);
              });
            }

            const site_prms_details: Array<any> = [];
            if (site_prms_details && site_prms_array.length > 0) {
              for (let i = 0; i < site_prms_array.length; i++) {
                let ps_max: string = Math.max(
                  site_prms_ps_array[i],
                  site_prms_pspointe_array[i],
                  site_prms_pshph_array[i],
                  site_prms_pshch_array[i],
                  site_prms_pshpe_array[i],
                  site_prms_pshce_array[i]
                ).toString();
                ps_max += " kW";
                site_prms_details[i] = [
                  site_prms_array[i],
                  ps_max,
                  site_prms_segment_array[i],
                ];
              }
            }

            // PCE
            const site_pces_car = ws2string(item_data["d:CAR"]);
            const site_pces_car_array: Array<any> = [];
            if (site_pces_car && site_pces_car.length > 0) {
              site_pces_car.split(";").forEach((site_pce_car) => {
                site_pces_car_array.push(
                  ws2float(site_pce_car).replace(".00", "") + " MWh"
                );
              });
            }

            const site_pces_profil = ws2string(item_data["d:Profil"]);
            const site_pces_profil_array: Array<any> = [];
            if (site_pces_profil && site_pces_profil.length > 0) {
              site_pces_profil.split(";").forEach((site_pce_profil) => {
                site_pces_profil_array.push(site_pce_profil);
              });
            }

            const site_pces_details: Array<any> = [];
            if (site_pces_details && site_pces_array.length > 0) {
              for (let i = 0; i < site_pces_array.length; i++) {
                site_pces_details[i] = [
                  site_pces_array[i],
                  site_pces_car_array[i],
                  site_pces_profil_array[i],
                ];
              }
            }

            datasResults.push({
              id: ws2string(item_data["d:SiteId"]),
              code: ws2string(item_data["d:SiteCodeInterne"]),
              name: ws2string(item_data["d:SiteNom"]),
              pce_count: ws2int(item_data["d:NombrePce"]),
              prm_count: ws2int(item_data["d:NombrePrm"]),
              pce: ws2string(item_data["d:PCE"]),
              prm: ws2string(item_data["d:PRM"]),
              pce_array: site_pces_array,
              pce_details: site_pces_details,
              prm_array: site_prms_array,
              prm_details: site_prms_details,
              pdl_array: site_pces_array.concat(site_prms_array),
              address_street_number: ws2string(item_data["d:SiteRueNumero"]),
              address_street_name: ws2string(item_data["d:SiteRueNom"]),
              address_zip_code: ws2string(item_data["d:SiteCodePostal"]),
              address_city: ws2string(item_data["d:SiteVille"]),
              total_to_pay: ws2float(item_data["d:TotalAPayer"]),
              payment_is_late: ws2string(item_data["d:RetartPaiement"]),
              total_late: ws2float(item_data["d:TotalRetard"]),
              ps: ws2string(item_data["d:PS"]),
              ps_point: ws2string(item_data["d:PSPointe"]),
              pshph: ws2string(item_data["d:PSHPH"]),
              pshch: ws2string(item_data["d:PSHCH"]),
              pshpe: ws2string(item_data["d:PSHPE"]),
              pshce: ws2string(item_data["d:PSHCE"]),
              // 'ps_max': ws2string(ps_max),
              segment: ws2string(item_data["d:Segment"]),
              ref_e: ws2string(item_data["d:RefE"]),
              car: ws2string(item_data["d:CAR"]),
              profil: ws2string(item_data["d:Profil"]),
              ref_g: ws2string(item_data["d:RefG"]),
              latitude: coordinates.lat,
              longitude: coordinates.lng,
            });
          });
          sites.value = datasResults;

          // Stockage des données utiles pour les champs d'autocompletion du site
          const site_name_list_data: Array<any> = [];
          const site_code_list_data: Array<any> = [];
          const site_address_list_data: Array<any> = [];
          const site_pce_list_data: Array<any> = [];
          const site_prm_list_data: Array<any> = [];

          datasResults.forEach((data) => {
            if (data.name && site_name_list_data.indexOf(data.name) === -1)
              site_name_list_data.push(data.name);
            if (data.code && site_code_list_data.indexOf(data.code) === -1)
              site_code_list_data.push(data.code);

            const full_address = generateSiteAddress(
              data.address_street_number,
              data.address_street_name,
              data.address_zip_code,
              data.address_city
            );
            if (
              full_address &&
              site_address_list_data.indexOf(full_address) === -1
            )
              site_address_list_data.push(full_address);

            data.pce_array.forEach((site_pce: any) => {
              if (site_pce_list_data.indexOf(site_pce) === -1)
                site_pce_list_data.push(site_pce);
            });

            data.prm_array.forEach((site_prm: any) => {
              if (site_prm_list_data.indexOf(site_prm) === -1)
                site_prm_list_data.push(site_prm);
            });
          });

          site_name_list.value = site_name_list_data;
          site_name_list.value = site_name_list_data;
          site_address_list.value = site_address_list_data;
          site_pce_list.value = site_pce_list_data;
          site_prm_list.value = site_prm_list_data;
        } else {
          return "sites_error";
        }

        if (!datas[3]?.error) {
          console.log(datas[3].result.entry);
          default_circle_chart.value = get_datas_from_ws_response(
            "circle_chart",
            datas[3].result.entry
          );
        } else {
          return "circle_chart_error";
        }

        if (!datas[4]?.error) {
          request_data.value = datas[4].datas.requests;
          energies_data.value = datas[4].datas.energies;
        } else {
          return "requests_error";
        }

        return "success";
      } catch (e) {
        console.log(e);
        return "unknown";
      }
    };

    const load_background_datas_from_api = async function (
      $api: any
    ): Promise<string> {
      const filters = {
        MoisDebut: monthStart.value,
        AnneeDebut: yearStart.value,
        MoisFin: monthEnd.value,
        AnneeFin: yearEnd.value,
      };

      try {
        const datas = await Promise.all([
          $api.account.getHistory(email.value, jsonToWsFilters(filters)),
          $api.account.getBillings(
            email.value,
            jsonToWsFilters(defaultBillsFilters())
          ),
          $api.account.getReadings(email.value, jsonToWsFilters(filters)),
        ]);
        if (!datas[0]?.error) {
          default_bar_chart.value = get_datas_from_ws_response(
            "bar_chart",
            datas[0].result.entry
          );
        } else {
          return "bar_chart_error";
        }

        if (!datas[1]?.error) {
          default_bills.value = get_datas_from_ws_response(
            "bills",
            datas[1].result.entry
          );
        } else {
          return "bills_error";
        }

        if (!datas[2]?.error) {
          default_consumptions.value = get_datas_from_ws_response(
            "consumptions",
            datas[2].result.entry
          );
        } else {
          return "consumptions_error";
        }

        return "success";
      } catch (e) {
        console.log(e);
        return "unknown";
      }
    };

    const set_site_default_datas = function (
      site_id: any,
      type: string,
      value: any
    ) {
      const sites_default_datas_index = sites_default_datas.value.findIndex(
        (x: any) => x.id == site_id
      );
      let index = sites_default_datas_index;
      if (sites_default_datas_index === -1) {
        sites_default_datas.value.push({ id: site_id });
        index = sites_default_datas.value.length - 1;
      }
      sites_default_datas.value[index][type] = value;
    };

    const load_site_datas_from_api = async function (
      $api: any,
      site_id: string
    ) {
      const site_datas = sites.value.find(
        (x: any) => parseInt(x.id) === parseInt(site_id)
      );
      const sites_default_datas_index = sites_default_datas.value.findIndex(
        (x: any) => x.id == site_id
      );
      try {
        const data = await $api.account.getBillings(
          email.value,
          jsonToWsFilters(defaultBillsFilters(site_id))
        );
        if (!data.error) {
          const datas = get_datas_from_ws_response("bills", data.result.entry);
          set_site_default_datas(site_id, "default_bills", datas);

          let has_pce = false;
          if (site_datas.pce_count > 0) has_pce = true;

          const currentDate = new Date();
          monthStart.value = ("0" + (currentDate.getMonth() + 1)).slice(-2);
          yearStart.value = currentDate.getFullYear().toString();
          monthEnd.value = monthStart.value;
          yearEnd.value = currentDate.getFullYear().toString();

          monthStart.value = ("0" + currentDate.getMonth()).slice(-2);
          //yearStart = currentDate.getFullYear();
          if (monthStart.value === "00") {
            monthStart.value = "12";
            monthEnd.value = "01";
            yearStart.value = (currentDate.getFullYear() - 1).toString();
          } else
            monthEnd.value = ("0" + (currentDate.getMonth() + 1)).slice(-2);
          yearEnd.value = currentDate.getFullYear().toString();

          const filters: any = {
            SiteId: site_id,
            MoisDebut: monthStart.value,
            AnneeDebut: yearStart.value,
            MoisFin: monthEnd.value,
            AnneeFin: yearEnd.value,
          };

          const dataPromises = await Promise.all([
            $api.account.getReadings(
              email.value,
              jsonToWsFilters(defaultConsumptionsFilters(site_id, has_pce))
            ),
            $api.graphs.getCamenbert(email.value, jsonToWsFilters(filters)),
            $api.account.getHistory(email.value, jsonToWsFilters(filters)),
          ]);
          if (!dataPromises?.[0].error) {
            const datas = get_datas_from_ws_response(
              "consumptions",
              dataPromises?.[0]?.result.entry
            );
            set_site_default_datas(site_id, "default_consumptions", datas);
          } else {
            return "error";
          }

          if (!dataPromises?.[1].error) {
            const datas = get_datas_from_ws_response(
              "circle_chart",
              dataPromises?.[1].result.entry
            );
            set_site_default_datas(site_id, "default_circle_chart", datas);
          } else {
            return "error";
          }

          if (!dataPromises?.[2].error) {
            const datas = get_datas_from_ws_response(
              "bar_chart",
              dataPromises?.[2]?.result.entry
            );
            set_site_default_datas(site_id, "default_bar_chart", datas);
          } else {
            return "error";
          }

          return "success";
        } else {
          return "bills_error";
        }
      } catch (e) {
        console.log(e);
        return "error";
      }
    };

    return {
      email,
      token,
      user_id,
      ticket_access,
      login_timestamp,
      actualities,
      account,
      sites,
      sites_default_datas,
      default_circle_chart,
      default_bar_chart,
      default_bills,
      default_consumptions,
      global_has_pce,
      global_has_prm,
      site_name_list,
      site_code_list,
      site_address_list,
      site_pce_list,
      site_prm_list,
      show_banner,
      request_data,
      status,
      options,
      fields,
      energies_data,
      tickets,
      ticket,
      last_visited_pages,
      site_default_datas,
      set_login_infos,
      set_request_infos,
      load_datas_from_api,
      load_background_datas_from_api,
      load_site_datas_from_api,
      get_datas_from_ws_response,
    };
  },
  {
    persist: true,
  }
);
