import HttpFactory from "@/repository/factory";

class ActualitiesModule extends HttpFactory {
  private RESOURCE = "actualities";

  async getActualities(): Promise<any> {
    return await this.call<any>("GET", `${this.RESOURCE}`);
  }
}

export default ActualitiesModule;
