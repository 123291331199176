import HttpFactory from "@/repository/factory";

class RequestModule extends HttpFactory {
  private RESOURCE = "request";

  async getRequestList(): Promise<any> {
    return await this.call<any>("GET", `${this.RESOURCE}/list`);
  }

  async getTicketList(data: any): Promise<any> {
    return await this.call<any>("POST", `ticket/list`, data);
  }

  async nextRequest(data: any): Promise<any> {
    return await this.call<any>("POST", `ticket/create`, data);
  }

  async getStatusList(): Promise<any> {
    return await this.call<any>("GET", `status/list`);
  }

  async getOptionsList(): Promise<any> {
    return await this.call<any>("GET", `options/list`);
  }

  async getFieldList(): Promise<any> {
    return await this.call<any>("GET", `field/list`);
  }
}

export default RequestModule;
